/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllUsers,
  searchUsers,
  createUser,
  deleteMultipleUsers,
  getUserById,
  updateUser,
  getUserByIdWithData,
} from "@services/UsersBackend.api";

const state = {
  searchResultUsers: [],
  searchTermUser: "",
  usersGlobalCount: 0,
  usersLocalCount: 0,
  numberOfRows: 5,
};

const getters = {
  getSearchResultUsers(state) {
    return state.searchResultUsers;
  },
  getSearchTermUser(state) {
    return state.searchTermUser;
  },
  getUsersGlobalCount(state) {
    return state.usersGlobalCount;
  },
  getUsersLocalCount(state) {
    return state.usersLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllUsers({ commit }, data) {
    data.loadingFunction(true);
    await getAllUsers(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_USER", "");
        commit("SET_SEARCH_RESULT_USERS", response.data.payload);
        commit("SET_USERS_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_USERS_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async addUser({ commit }, data) {
    await createUser(data).then(
      (response) => {
        commit("ADD_USER", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },

  async fetchSearchResultUsers({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_USER", data.term);
    await searchUsers(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_USERS", response.data.payload);
        commit("SET_USERS_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_USERS_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getUser({}, data) {
    let res = {};
    await getUserById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async getUserByIdWithData({}, data) {
    let res = {};
    await getUserByIdWithData(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updateUser({ commit }, data) {
    const { id, ...toSend } = data;
    await updateUser(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_USER", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deleteUser({ commit }, data) {
    data.loadingFunction(true);
    await deleteMultipleUsers(data).then(
      () => {
        commit("DELETE_USERS", data.ids);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError(
          "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
        );
      }
    );
  },
};

const mutations = {
  SET_SEARCH_RESULT_USERS(state, data) {
    state.searchResultUsers = data;
  },
  SET_SEARCH_TERM_USER(state, data) {
    state.searchTermUser = data;
  },
  ADD_USER(state, data) {
    state.searchResultUsers.push(data);
  },
  DELETE_USERS(state, data) {
    const arr = [];
    state.searchResultUsers.forEach((element) => {
      if (!data.includes(element.id)) {
        arr.push(element);
      }
    });
    state.searchResultUsers = arr;
  },
  UPDATE_USER(state, data) {
    let idx = state.searchResultUsers.findIndex((obj) => obj.id === data.id);
    state.searchResultUsers[idx] = data;
  },
  SET_USERS_GLOBAL_COUNT(state, data) {
    state.usersGlobalCount = data;
  },
  SET_USERS_LOCAL_COUNT(state, data) {
    state.usersLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
