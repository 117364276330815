const appConfig = {
  defaultLocale: 'ar',
  locales: [
    {
      code: 'en',
      name: 'English',
      direction: 'ltr',
      contentKey: 'getContentEn'
    },
    {
      code: 'ar',
      name: 'عربية',
      direction: 'rtl',
      contentKey: 'getContentAr'
    }
  ]
}

export default {
  get cacheEnabled () {
    return true
  },
  get retries () {
    return 3
  },
  get timeout () {
    return 1500
  },
  get apiUrl () {
    let useSslDev = false
    let useSslProd = true
    return process.env.NODE_ENV === 'production'
        ? (useSslProd === true ? 'https://archive.assafir.com/api' : 'http://archive.assafir.com/api')
        : (useSslDev === true ? 'https://localhost/api' : 'http://localhost/api')
  },
  appConfig
}
