const state = {
    searchValue: '',
    baseQuery: {
        pageSize: 16,
        pageNumber: 1,
        order: 'DESC',
        sortBy: 'relevance',
        searchWithin: ['title', 'keywords']
    },
    resetQuery: {
        pageSize: 16,
        pageNumber: 1,
        order: 'DESC',
        sortBy: 'relevance',
        searchWithin: ['title', 'keywords']
    },
    resetPaginationQuery: {
        pageSize: 16,
        pageNumber: 1,
    }
}

const getters = {
    getSearchValue(state) {
        return state.searchValue;
    },
    getBaseQuery(state) {
        if (!Object.hasOwnProperty.call(state.baseQuery, 'searchWithin')) {
            state.baseQuery['searchWithin'] = ['title', 'keywords']
        }
        return state.baseQuery;
    },
    getResetQuery(state) {
        return state.resetQuery;
    },
    getResetPaginationQuery(state) {
        return state.resetPaginationQuery;
    }
}

const mutations = {
    SET_SEARCH_VALUE(state, value) {
        state.searchValue = value;
    },
    SET_BASE_QUERY(state, value) {
        state.baseQuery = value;
    },
    RESET_PAGE_NUMBER(state) {
        state.baseQuery.pageNumber = 1
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}