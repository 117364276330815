import { getFilterOptions } from '@services/Filter.api'
import { removeByAttr } from '@mixins/helpers'

export default {
    name:"Filter",

    props: {
        selectedValues: {
            type: Object,
            default() {return {}}
        }
    },

    data() {
        return {
            fromDate: new Date('02 January 1974').toISOString().substr(0, 10),
            toDate: new Date().toISOString().substr(0, 10),
            nowDate: new Date().toISOString().substr(0, 10),

            fromMenu: false,
            toMenu: false,

            resetFilters: [
                {
                    title: "البحث ضمن",
                    name: "searchWithin",
                    id: "searchWithin",
                    filteredOptions: [
                        { id: 'title', label: "العنوان"},
                        { id: "keywords", label: "الكلمات المفتاح"},
                        { id: "content", label: "نص الموضوع"}
                    ],
                    selectedOptions: [],
                    hasSearchBar: false
                }, {
                    title: "الموضوع",
                    name: "subject",
                    id: "subjects",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, {
                    title: "المكان",
                    name: "location",
                    id: "locations",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, {
                    title: "الأشخاص",
                    name: "person",
                    id: "people",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, {
                    title: "المؤسسات",
                    name: "organization",
                    id: "organizations",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, {
                    title: "المصدر",
                    name: "source",
                    id: "sources",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, {
                    title: "المؤلف",
                    name: "author",
                    id: "authors",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, {
                    title: "المصور",
                    name: "photographer",
                    id: "photographers",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, 
                {
                    title: "النوع",
                    name: "category",
                    id: "categories",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                },
                {
                    title: "نوع المقالات",
                    name: "type",
                    id: "types",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                },
                {
                    title: "التبویب",
                    name: "topic",
                    id: "topics",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }
            ],

            resetRadioFilters: [
                {
                    title: "التسلسل",
                    name: "order",
                    id: "order",
                    filteredOptions: [
                        { id: 'DESC', label: "تنازلي"},
                        { id: 'ASC', label: "تصاعدي"}
                    ],
                    selectedOption: 'DESC',
                    hasSearchBar: false
                }, {
                    title: "الترتيب",
                    name: "sortBy",
                    id: "sortBy",
                    filteredOptions: [
                        { id: 'relevance', label: "مدى الترابط"},
                        { id: 'date', label: "التاريخ"},
                        { id: 'source', label: "المصدر"},
                    ],
                    selectedOption: 'relevance',
                    hasSearchBar: false
                }
            ],

            radioFilters: [
                {
                    title: "التسلسل",
                    name: "order",
                    id: "order",
                    filteredOptions: [
                        { id: 'DESC', label: "تنازلي"},
                        { id: 'ASC', label: "تصاعدي"}
                    ],
                    selectedOption: 'DESC',
                    hasSearchBar: false
                }, {
                    title: "الترتيب",
                    name: "sortBy",
                    id: "sortBy",
                    filteredOptions: [
                        { id: 'relevance', label: "مدى الترابط"},
                        { id: 'date', label: "التاريخ"},
                        { id: 'source', label: "المصدر"},
                    ],
                    selectedOption: 'relevance',
                    hasSearchBar: false
                }
            ],

            filters: [
                {
                    title: "البحث ضمن",
                    name: "searchWithin",
                    id: "searchWithin",
                    filteredOptions: [
                        { id: 'title', label: "العنوان"},
                        { id: "keywords", label: "الكلمات المفتاح"},
                        { id: "content", label: "نص الموضوع"}
                    ],
                    selectedOptions: [],
                    hasSearchBar: false
                }, {
                    title: "الموضوع",
                    name: "subject",
                    id: "subjects",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, {
                    title: "المكان",
                    name: "location",
                    id: "locations",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, {
                    title: "الأشخاص",
                    name: "person",
                    id: "people",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, {
                    title: "المؤسسات",
                    name: "organization",
                    id: "organizations",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, {
                    title: "المصدر",
                    name: "source",
                    id: "sources",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, {
                    title: "المؤلف",
                    name: "author",
                    id: "authors",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, {
                    title: "المصور",
                    name: "photographer",
                    id: "photographers",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }, 
                {
                    title: "الإصدار",
                    name: "category",
                    id: "categories",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                },
                {
                    title: "النوع",
                    name: "type",
                    id: "types",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                },
                {
                    title: "التبویب",
                    name: "topic",
                    id: "topics",
                    filteredOptions: [],
                    selectedOptions: [],
                    searchValue: "",
                    hasSearchBar: true
                }
            ]
        }
    },

    mounted() {
        this.fillSelectedOptions()
    },

    methods: {
        close() {
            this.$emit('close')
        },

        cancel() {
            this.$emit('cancel')
        },

        fillSelectedOptions() {
            for (const key in this.selectedValues) {
                if (Object.hasOwnProperty.call(this.selectedValues, key)) {
                    if (key === 'order' || key === 'sortBy') {
                        this.radioFilters.map(filter => {
                            if (filter.name === key) {
                                filter.selectedOption = this.selectedValues[key][0]
                            }
                        })
                    } 
                    else if (key === 'searchWithin') {
                        var searchWithinList = {
                            title:"العنوان",
                            keywords:"الكلمات المفتاح",
                            content:"نص الموضوع"
                        }
               
                        this.filters.map(filter => {
                            if (filter.name === 'searchWithin') {
                                for (let i = 0; i < this.selectedValues['searchWithin'].length; i++) {
                                    var searchWithinId = this.selectedValues['searchWithin'][i]
                                    if (Object.hasOwnProperty.call(searchWithinList, searchWithinId)) {
                                        filter.selectedOptions.push(
                                            {
                                                id: searchWithinId, 
                                                label: searchWithinList[searchWithinId]
                                            }
                                        )

                                        removeByAttr(filter.filteredOptions, 'id', this.selectedValues[key][i])
                                    }
                                }
                            }
                        })
                    } 
                    else if (key === 'fromDate' || key === 'toDate') {
                        this[key] = this.selectedValues[key][0]
                    } else {
                        this.filters.map(filter => {
                            if (filter.name === key) {
                                for (let i = 0; i < this.selectedValues[key].length; i++) {
                                    filter.selectedOptions.push({id: i, label: this.selectedValues[key][i]})
                                    removeByAttr(filter.filteredOptions, 'label', this.selectedValues[key][i])
                                }
                            }
                        })
                    }
                }
            }
        },

        getQuery() {
            var query = {}
            if (this.fromDate != '1974-01-01') {
                query['fromDate'] = this.fromDate
            }
            if (this.toDate != this.nowDate) {
                query['toDate'] = this.toDate
            }

            for (let i = 0; i < this.radioFilters.length; i++) {
                var radioFilterName = this.radioFilters[i].name
                var radioFilterArray = []
                radioFilterArray.push(this.radioFilters[i].selectedOption)
                query[radioFilterName] = radioFilterArray
            }

            for (let i = 0; i < this.filters.length; i++) {
                var name = this.filters[i].name
                var array = []

                if (name == 'searchWithin' && this.filters[i].selectedOptions.length == 0) {
                    query[name] = ['title', 'keywords']
                } else if (name == 'searchWithin' && this.filters[i].selectedOptions.length > 0) {
                    array = this.filters[i].selectedOptions.map(option => option.id);
                    query[name] = array
                } else if (this.filters[i].selectedOptions.length > 0) {
                    array = this.filters[i].selectedOptions.map(option => option.label);
                    query[name] = array
                }
            }

            return query
        },

        reset() {
            this.fromDate = '1974-01-01'
            this.toDate = this.nowDate
            this.filters = JSON.parse(JSON.stringify(this.resetFilters));
            this.radioFilters = JSON.parse(JSON.stringify(this.resetRadioFilters));

            var query = this.getQuery()

            this.$emit('reset', query)
        },

        submit() {
            var query = this.getQuery()
            this.$emit('submit', query)
        },

        selectOption(option, entity) {
            var filter = this.getFilter(entity)
            var selectedOption = option
            selectedOption.searchValue = filter.searchValue
            filter.selectedOptions.push(selectedOption)
            removeByAttr(filter.filteredOptions, 'id', option.id)
        },

        unselectOption(option, entity) {
            var filter = this.getFilter(entity)
            removeByAttr(filter.selectedOptions, 'label', option.label)

            if (option.searchValue === filter.searchValue) {
                filter.filteredOptions.push(option)
            }
        },

        getFilter(id) {
            return this.filters.find(filter => filter.id === id);
        },

        async getFilters(value, entity) {
            var filter = this.getFilter(entity)
            var keyword = value.toLowerCase().trim()
            filter.searchValue = keyword

            if (keyword === '') {
                filter.filteredOptions = []
            } else {
                await getFilterOptions(entity, value).then(
                    response => {
                        var result = response.data.payload
                        filter.filteredOptions = result.map(option => {
                            var label = ""
                            if (option.name) {
                                label = option.name
                            } else if (option.category) {
                                label = option.category
                            } else if (option.type) {
                                label = option.type
                            } else if (option.topic) {
                                label = option.topic
                            }
                            
                            return {
                                id: option.id,
                                label: label
                            }
                        })
                    }, err => { 
                        console.log('inside err ', err)
                    }
                )
            }
        },

        toggleOptionsList(id) {
            var currentDisplay = this.$refs[id][0].style.display
            var displayCriteria = currentDisplay === 'none' ? 'block' : 'none'
            var displayIconDown = displayCriteria === 'none' ? 'block' : 'none'

            this.$refs[id][0].style.display = displayCriteria
            this.$refs['icon_left_'+ id][0].style.display = displayCriteria
            this.$refs['icon_down_'+ id][0].style.display = displayIconDown
        }
    },

    watch: {
        $route: {
            deep: true,
            handler() {
                this.close()
            }
        }
    }
}