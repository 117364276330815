import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/users";

const getAllUsers = (data) => {
  return service.get(END_POINT + objToQuery(data));
};

const getUserById = (id) => {
  return service.get(END_POINT + "/by-id/" + id);
};

const getUserByIdWithData = (id) => {
  return service.get(END_POINT + "/by-id-with-data/" + id);
};

const searchUsers = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const createUser = (data) => {
  return service.post(END_POINT, data);
};

const updateUser = (id, data) => {
  return service.put(END_POINT + "/backend/" + id, data);
};

const deleteMultipleUsers = (data) => {
  return service.delete(END_POINT + "/hard-delete-multiple", {
    data: JSON.stringify({ ids: data.ids }),
  });
};

export {
  getAllUsers,
  getUserById,
  searchUsers,
  createUser,
  updateUser,
  deleteMultipleUsers,
  getUserByIdWithData
};
