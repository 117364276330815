/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllTopics,
  searchTopics,
  createTopic,
  deleteMultipleTopics,
  getTopicById,
  updateTopic,
  mergeTopics,
} from "@services/Topic.api";

const state = {
  searchResultTopics: [],
  searchTermTopic: "",
  topicsGlobalCount: 0,
  topicsLocalCount: 0,
  numberOfRows: 5,
};

const getters = {
  getSearchResultTopics(state) {
    return state.searchResultTopics;
  },
  getSearchTermTopic(state) {
    return state.searchTermTopic;
  },
  getTopicsGlobalCount(state) {
    return state.topicsGlobalCount;
  },
  getTopicsLocalCount(state) {
    return state.topicsLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllTopics({ commit }, data) {
    data.loadingFunction(true);
    await getAllTopics(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_TOPIC", "");
        commit("SET_SEARCH_RESULT_TOPICS", response.data.payload);
        commit("SET_TOPICS_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_TOPICS_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async addTopic({ commit }, data) {
    await createTopic(data).then(
      (response) => {
        commit("ADD_TOPIC", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },

  async fetchSearchResultTopics({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_TOPIC", data.term);
    await searchTopics(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_TOPICS", response.data.payload);
        commit("SET_TOPICS_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_TOPICS_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getTopic({}, data) {
    let res = {};
    await getTopicById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updateTopic({ commit }, data) {
    const { id, ...toSend } = data;
    await updateTopic(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_TOPIC", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deleteTopic({ commit }, data) {
    data.loadingFunction(true);
    await deleteMultipleTopics(data).then(
      () => {
        commit("DELETE_TOPICS", data.ids);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError(
          "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
        );
      }
    );
  },
  async mergeTopic({ commit }, data) {
    data.loadingFunction(true);
    await mergeTopics(data).then(
      () => {
        commit("DELETE_TOPICS", data.otherIds);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError("هناك خطأ ما");
      }
    );
  },
};

const mutations = {
  SET_SEARCH_RESULT_TOPICS(state, data) {
    state.searchResultTopics = data;
  },
  SET_SEARCH_TERM_TOPIC(state, data) {
    state.searchTermTopic = data;
  },
  ADD_TOPIC(state, data) {
    state.searchResultTopics.push(data);
  },
  DELETE_TOPICS(state, data) {
    const arr = [];
    state.searchResultTopics.forEach((element) => {
      if (!data.includes(element.id)) {
        arr.push(element);
      }
    });
    state.searchResultTopics = arr;
  },
  UPDATE_TOPIC(state, data) {
    let idx = state.searchResultTopics.findIndex((obj) => obj.id === data.id);
    state.searchResultTopics[idx] = data;
  },
  SET_TOPICS_GLOBAL_COUNT(state, data) {
    state.topicsGlobalCount = data;
  },
  SET_TOPICS_LOCAL_COUNT(state, data) {
    state.topicsLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
