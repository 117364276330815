import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/articles";

const getAllArticles = (data) => {
  return service.get(END_POINT + "/virtuals" + objToQuery(data));
};

const getArticleById = (id) => {
  return service.get(END_POINT + "/virtuals/by-id/" + id);
};

const searchArticles = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/virtuals/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const updateArticle = (id, data) => {
  return service.put(END_POINT + "/" + id, data);
};

const deleteArticle = (id) => {
  return service.delete(END_POINT + "/hard-delete-one/recursive/" + id);
};

export { getAllArticles, getArticleById, searchArticles, updateArticle, deleteArticle };
