import Vue from 'vue'
import Vuex from 'vuex'
import Router from 'vue-router'
import createPersistedState from 'vuex-persistedstate'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import auth from './modules/auth'
import content from './modules/content'
import localStoragePlugin from './plugins/localStorage'
import notification from './modules/notification'
import search from './modules/search'
import images from './modules/images'
import articles from './modules/articles'
import charts from './modules/charts'
import loaders from './modules/loaders'
import languages from './modules/languages'
import authors from './modules/authors'
import photographers from './modules/photographers'
import categories from './modules/categories'
import types from './modules/types'
import topics from './modules/topics'
import sources from './modules/sources'
import userQueries from './modules/userQueries'
import entities from './modules/entities'
import thesauruses from './modules/thesauruses'
import users from './modules/users'
import imagesBackend from './modules/imagesBackend'
import articlesBackend from './modules/articlesBackend'
import userRequests from './modules/userRequests'

Vue.use(Vuex)
Vue.use(Router)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {},
  actions,
  getters,
  mutations,
  modules: {
    auth,
    content,
    notification,
    search,
    images,
    articles,
    charts,
    loaders,
    languages,
    authors,
    photographers,
    categories,
    types,
    topics,
    sources,
    userQueries,
    entities,
    thesauruses,
    users,
    imagesBackend,
    articlesBackend,
    userRequests
  },
  plugins: [localStoragePlugin, createPersistedState({
    storage: window.sessionStorage,
  })],
  strict: debug
})
