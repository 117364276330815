export default {
    name:"SearchBar",

    data() {
        return {
            basicQuery: null,
            filterExplainTooltipShow: false
        }
    },

    props: {
        searchValue: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    mounted () {
        this.basicQuery = this.searchValue;

        if (sessionStorage.getItem('firstNavigation') === false || 
            sessionStorage.getItem('firstNavigation') === null) {
            setTimeout(() => {this.filterExplainTooltipShow =  true}, 2000);
            setTimeout(() => {this.disableToolTip()}, 20000);
        }
    },
   
    methods: {
        search() {
            this.$emit('search', this.basicQuery)
        },

        filter() {
            this.$emit('filter')
        },

        disableToolTip() {
            this.filterExplainTooltipShow = false;
            sessionStorage.setItem('firstNavigation', false);
        }
    },

    watch: {
        searchValue() {
            this.basicQuery = this.searchValue
        }
    }
}