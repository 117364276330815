/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllPhotographers,
  searchPhotographers,
  createPhotographer,
  deleteMultiplePhotographers,
  getPhotographerById,
  updatePhotographer,
  mergePhotographers,
} from "@services/Photographers.api";

const state = {
  searchResultPhotographers: [],
  searchTermPhotographer: "",
  photographersGlobalCount: 0,
  photographersLocalCount: 0,
  numberOfRows: 5,
};

const getters = {
  getSearchResultPhotographers(state) {
    return state.searchResultPhotographers;
  },
  getSearchTermPhotographer(state) {
    return state.searchTermPhotographer;
  },
  getPhotographersGlobalCount(state) {
    return state.photographersGlobalCount;
  },
  getPhotographersLocalCount(state) {
    return state.photographersLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllPhotographers({ commit }, data) {
    data.loadingFunction(true);
    await getAllPhotographers(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_PHOTOGRAPHER", "");
        commit("SET_SEARCH_RESULT_PHOTOGRAPHERS", response.data.payload);
        commit("SET_PHOTOGRAPHERS_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_PHOTOGRAPHERS_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async addPhotographer({ commit }, data) {
    await createPhotographer(data).then(
      (response) => {
        commit("ADD_PHOTOGRAPHER", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },

  async fetchSearchResultPhotographers({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_PHOTOGRAPHER", data.term);
    await searchPhotographers(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_PHOTOGRAPHERS", response.data.payload);
        commit("SET_PHOTOGRAPHERS_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_PHOTOGRAPHERS_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getPhotographer({}, data) {
    let res = {};
    await getPhotographerById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updatePhotographer({ commit }, data) {
    const { id, ...toSend } = data;
    await updatePhotographer(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_PHOTOGRAPHER", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deletePhotographer({ commit }, data) {
    data.loadingFunction(true);
    await deleteMultiplePhotographers(data).then(
      () => {
        commit("DELETE_PHOTOGRAPHERS", data.ids);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError(
          "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
        );
      }
    );
  },
  async mergePhotographer({ commit }, data) {
    data.loadingFunction(true);
    await mergePhotographers(data).then(
      () => {
        commit("DELETE_PHOTOGRAPHERS", data.otherIds);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError("هناك خطأ ما");
      }
    );
  },
};

const mutations = {
  SET_SEARCH_RESULT_PHOTOGRAPHERS(state, data) {
    state.searchResultPhotographers = data;
  },
  SET_SEARCH_TERM_PHOTOGRAPHER(state, data) {
    state.searchTermPhotographer = data;
  },
  ADD_PHOTOGRAPHER(state, data) {
    state.searchResultPhotographers.push(data);
  },
  DELETE_PHOTOGRAPHERS(state, data) {
    const arr = [];
    state.searchResultPhotographers.forEach((element) => {
      if (!data.includes(element.id)) {
        arr.push(element);
      }
    });
    state.searchResultPhotographers = arr;
  },
  UPDATE_PHOTOGRAPHER(state, data) {
    let idx = state.searchResultPhotographers.findIndex(
      (obj) => obj.id === data.id
    );
    state.searchResultPhotographers[idx] = data;
  },
  SET_PHOTOGRAPHERS_GLOBAL_COUNT(state, data) {
    state.photographersGlobalCount = data;
  },
  SET_PHOTOGRAPHERS_LOCAL_COUNT(state, data) {
    state.photographersLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
