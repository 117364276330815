/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllImages,
  searchImages,
  getImageById,
  deleteImage,
  updateImage,
} from "@services/ImagesBackend.api";

const state = {
  searchResultImages: [],
  searchTermImage: "",
  imagesGlobalCount: 0,
  imagesLocalCount: 0,
  numberOfRows: 6,
};

const getters = {
  getSearchResultImages(state) {
    return state.searchResultImages;
  },
  getSearchTermImage(state) {
    return state.searchTermImage;
  },
  getImagesGlobalCount(state) {
    return state.imagesGlobalCount;
  },
  getImagesLocalCount(state) {
    return state.imagesLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllImages({ commit }, data) {
    data.loadingFunction(true);
    await getAllImages(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_IMAGE", "");
        commit("SET_SEARCH_RESULT_IMAGES", response.data.payload);
        commit("SET_IMAGES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_IMAGES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async fetchSearchResultImages({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_IMAGE", data.term);
    await searchImages(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_IMAGES", response.data.payload);
        commit("SET_IMAGES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_IMAGES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getImage({}, data) {
    let res = {};
    await getImageById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updateImage({ commit }, data) {
    const { id, ...toSend } = data;
    await updateImage(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_IMAGE", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deleteImage({ commit }, data) {
    data.loadingFunction(true);
    await deleteImage(data.imageId).then(
      (response) => {
        commit("DELETE_IMAGE", response.data.payload.id);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError(
          "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
        );
      }
    );
  },
};

const mutations = {
  SET_SEARCH_RESULT_IMAGES(state, data) {
    state.searchResultImages = data;
  },
  SET_SEARCH_TERM_IMAGE(state, data) {
    state.searchTermImage = data;
  },
  DELETE_IMAGE(state, data) {
    const arr = [];
    state.searchResultImages.forEach((element) => {
      if (!(parseInt(element.id) === parseInt(data)) ) {
        arr.push(element);
      }
    });
    state.searchResultImages = arr;
  },
  UPDATE_IMAGE(state, data) {
    let idx = state.searchResultImages.findIndex((obj) => obj.id === data.id);
    state.searchResultImages[idx] = data;
  },
  SET_IMAGES_GLOBAL_COUNT(state, data) {
    state.imagesGlobalCount = data;
  },
  SET_IMAGES_LOCAL_COUNT(state, data) {
    state.imagesLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
