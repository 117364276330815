import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/user-requests";

const getAllUserRequests = (data) => {
  return service.get(END_POINT + objToQuery(data));
};

const getUserRequestsCount = (data) => {
    return service.get(END_POINT + "/count" + objToQuery(data));
  };

const getUserRequestById = (id) => {
  return service.get(END_POINT + "/by-user-id/" + id);
};

const getUserRequestsWordCloud = (count) => {
    return service.get(END_POINT + "/word-cloud" + "?sortBy=_id&order=DESC&pageNumber=1&pageSize=" + count, {useCache: false});
}

const getUserRequestsQueryCloud = () => {
    return service.get(END_POINT + "/query-cloud" + "?sortBy=_id&order=DESC&pageNumber=1&pageSize=1000");
}

// const searchUserRequests = (searchTerm, request) => {
//   return service.get(
//     END_POINT +
//       "/basic-search/" +
//       encodeURIComponent(searchTerm) +
//       objToQuery(request)
//   );
// };

const createUserRequest = (data) => {
  return service.post(END_POINT, data);
};


export {
  getAllUserRequests,
  getUserRequestsCount,
  getUserRequestById,
  createUserRequest,
  getUserRequestsWordCloud,
  getUserRequestsQueryCloud
};
