import SearchBar from '@shared/SearchBar/SearchBar.vue'
import SearchFilter from '@shared/Filter/Filter.vue'
import { mapGetters, mapMutations, mapActions } from "vuex";
// import { queryStringToObject } from '@mixins/helpers'

export default {
    name: "TheHeader",

    components: {
        SearchBar,
        SearchFilter
    },

    data() {
        return {
            drawer: false,
            searchValue: '',
            baseQuery: '',
            showFilter: false,
            fullPath: null,
            hideMainHeader: true,
            selectedPage: "summary",
            disableSearchBar: false,
            disablePagesButtons: false
        }
    },

    computed: {
        ...mapGetters('search', ['getSearchValue', 'getBaseQuery', 'getResetQuery' ]),
        ...mapGetters('auth', ['isLoggedIn']),
    },

    methods: {
        ...mapMutations('search', ['SET_BASE_QUERY', 'RESET_PAGE_NUMBER']),
        ...mapActions('auth', ['logout']),

        search(value) {
            var newQuery = JSON.parse(JSON.stringify(this.getBaseQuery));
            newQuery['value'] = value

            this.SET_BASE_QUERY(newQuery)

            var currentPage = this.$route.name
            this.goToPage(currentPage)
            this.$router.go()
        },

        setSearchValue() {
            var value = this.$route.query.value
            if (value) {
                if (value.constructor === Array) {
                    value = value[0]
                }
                this.searchValue = value
            } 
            else {
                this.searchValue = ""
                // var val = this.getSearchValue
                // console.log('setSearchValue ', this.getSearchValue);
                // if(val.constructor === Array) {
                //     val = val[0]
                // }
                // this.searchValue = val
                // console.log('this.searchValue ', this.searchValue);
            }
           
        },

        goToPageRouter(pageName) {
            this.RESET_PAGE_NUMBER()
            this.$router.push({
                name: pageName,
                // params: {
                //     value: this.searchValue
                // },
                query: this.getBaseQuery
            })
        },

        goToPage(pageName) {
            // this.setSearchValue()

            // if (this.searchValue) {
                this.goToPageRouter(pageName)
            // } else {
            //     this.$router.push({
            //         name: 'home'
            //     })
            // }
        },

        toggleFilter() {
            this.showFilter = !this.showFilter
        },

        hideFilter() {
            this.showFilter = false
        },
        
        cancelFilter() {
            this.hideFilter()
        },

        submitFilter(query) {
            var finalQuery = {...this.getBaseQuery, ...query}
            this.SET_BASE_QUERY(finalQuery)

            var currentPage = this.$route.name
            this.goToPage(currentPage)
            this.$router.go()
            this.hideFilter()
        },

        // mergeBaseQueryAndQuery (baseQuery, query) {
        //     var mergedObject = {...baseQuery}
        //     for (const key in query) {
        //         if (Object.hasOwnProperty.call(query, key)) {
        //             if (key === 'order' || key === 'sortBy' || key === 'searchWithin') {
        //                 mergedObject[key] = query[key]
        //             } else {
        //                 if (Object.hasOwnProperty.call(baseQuery, key)) {
        //                     mergedObject[key] = [...baseQuery[key]]
            
        //                     if (Array.isArray(query[key])) {
        //                         for (let i = 0; i < query[key].length; i++) {
        //                             if (!baseQuery[key].includes(query[key][i])) {
        //                                 mergedObject[key].push(query[key][i])
        //                             }
        //                         }
        //                     } else {
        //                         if (!baseQuery[key].includes(query[key])) {
        //                             mergedObject[key].push(query[key])
        //                         }
        //                     }
        //                 } else {
        //                     mergedObject[key] = query[key]
        //                 }
        //             }
        //         }
        //     }
        //     return mergedObject
        // },

        resetFilter(query) {
            var finalQuery = {...this.getResetQuery, ...query}
            this.SET_BASE_QUERY(finalQuery)

            var currentPage = this.$route.name
            this.goToPage(currentPage)
            this.$router.go()
            this.hideFilter()
        },

        setHideMainHeader() {
            this.fullPath = this.$route.fullPath
            if (this.fullPath === '/home') {
                this.hideMainHeader = true
            } else {
                this.hideMainHeader = false
            }
        },

        setBaseQuery() {
            var query = this.$route.query
            var formattedQuery = this.getFormattedQuery(query)
            // formattedQuery = formattedQuery.substring(1);

            var modifiedQuery = this.setQueryValuesToArrays(formattedQuery)
            // queryStringToObject(formattedQuery)//

            if (query) {
                if (Object.keys(query).length === 0 && query.constructor === Object) {
                    this.SET_BASE_QUERY(this.getBaseQuery)
                } else {
                    this.SET_BASE_QUERY(modifiedQuery)
                }
            } else {
                this.SET_BASE_QUERY(this.getResetQuery)
            }
        },

        getFormattedQuery(query) {
            var formattedQuery = "?"

            for (const key in query) {
                if (Object.hasOwnProperty.call(query, key)) {
                    if (query[key]) {
                        if (Array.isArray(query[key])) {
                            for (let i = 0; i < query[key].length; i++) {
                                formattedQuery += key + "=" + query[key][i] + '&'
                            }
                        } else {
                            formattedQuery += key + "=" + query[key] + '&'
                        }
                    }
                }
            }

            formattedQuery = formattedQuery.substring(0, formattedQuery.length - 1);
            return formattedQuery
        },

        setQueryValuesToArrays(data) {
            data = data.substring(1);
            var pairs = data.split('&'); 
            var queryObject = {}
    
            for (let i = 0; i < pairs.length; i++) {
                var key = pairs[i].split("=")[0]
                var value = pairs[i].split("=")[1]
    
                if (!Object.prototype.hasOwnProperty.call(queryObject, key)) {
                    queryObject[key] = []
                } 
    
                queryObject[key].push(value)
            }
    
           return queryObject
        },

        signout() {
            this.logout()
            this.$router.push({
                name: 'signin',
            })
        }
    },

    watch:{
        $route: {
            immediate: true,
            deep: true,
            handler(to, from) {
                this.setHideMainHeader()
                this.setSearchValue()

                if (to.name === "home") {
                    this.SET_BASE_QUERY(this.getResetQuery)
                } else {
                    this.setBaseQuery()
                }

                if (this.$route.name == 'signup') {
                    this.selectedPage = 'signin'
                } else {
                    this.selectedPage = this.$route.name
                }

                if (this.$route.name == 'profile' 
                    || this.$route.name == 'signup' 
                    || this.$route.name == 'signin') {
                    this.disableSearchBar = true
                } else {
                    this.disableSearchBar = false
                }

                if (to.name == 'signin' 
                    && from.name == 'home') {
                    this.disablePagesButtons = true
                } else {
                    this.disablePagesButtons = false
                }
            }
        }
    } 
}