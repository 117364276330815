import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/sources";

const getAllSources = (data) => {
  return service.get(END_POINT + objToQuery(data));
};

const getSourceById = (id) => {
  return service.get(END_POINT + "/by-id/" + id);
};

const searchSources = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const createSource = (data) => {
  return service.post(END_POINT, data);
};

const updateSource = (id, data) => {
  return service.put(END_POINT + "/" + id, data);
};

const deleteOneSource = (id) => {
  return service.delete(END_POINT + "/hard-delete-one/" + id);
};

const deleteMultipleSources = (data) => {
  return service.delete(END_POINT + "/hard-delete-multiple", {
    data: JSON.stringify({ ids: data.ids }),
  });
};

const mergeSources = (data) => {
  return service.post(
    END_POINT + "/merge",
    JSON.stringify({ mainId: data.mainId, otherIds: data.otherIds })
  );
};

export {
  getAllSources,
  getSourceById,
  searchSources,
  createSource,
  updateSource,
  deleteOneSource,
  deleteMultipleSources,
  mergeSources,
};
