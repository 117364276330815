<template>
  <div style="margin: 50px;">
    <pre>{{ JSON.stringify(article, null, 2) }}</pre>
    <v-img max-width="200" aspect-ratio="1" class="grey lighten-2"
      :src="apiUrl + '/entities/images/raw/' + (article.newPdfPath && article.newPdfPath.length > 0 ? article.newPdfPath : article.oldPdfPath) + '/200'" 
      :lazy-src="apiUrl + '/entities/images/raw/' + (article.newPdfPath && article.newPdfPath.length > 0 ? article.newPdfPath : article.oldPdfPath) + '/200'">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/helpers/config'

export default {
  name: 'ArticleView',
  data () {
    return {
      apiUrl: config.apiUrl,
      loading: false,
      article: {}
    }
  },
  mounted () {
    this.$vuetify.rtl = false
    if(this.$route.params.safirId && this.$route.params.safirId.length > 0) {
      this.loading = true
      axios.all([this.$store.dispatch('elasticSearchBySafirId', {entity: 'articles', val: this.$route.params.safirId, query: ''})]).then(axios.spread((response) => {
        if (response.data.status === 'SUCCESS') {
          this.loading = false
          this.article = response.data.payload
        } else {
          this.loading = false
          this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
        }
      })).catch(() => {
        this.loading = false
        this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
      })
    } else {
      this.$router.push({ path: '/home', query: this.$route.query })
    }
  }
}
</script>
