import { getArticlesDateStatistics, getArticlesDateStatisticsNoValue,
        getImagesDateStatistics, getImagesDateStatisticsNoValue,
        getArticlesTopicStatistics, getArticlesTopicStatisticsNoValue,
        getArticlesEntitiesStatistics, getArticlesEntitiesStatisticsNoValue,
        getArticlesTypeStatistics, getArticlesTypeStatisticsNoValue,
        getArticlesSourceStatistics, getArticlesSourceStatisticsNoValue,
        getImagesSourceStatistics,  getImagesSourceStatisticsNoValue,
        getArticlesAuthorsStatistics, getArticlesAuthorsStatisticsNoValue,
        getImagesPhotographersStatistics, getImagesPhotographersStatisticsNoValue } from '@services/Charts.api'

const state = {
    articlesDateStatistics: [],
    imagesDateStatistics: [],
    dateStatisticsLabels: [],

    articlesTopicStatistics: [],
    topicStatisticsLabels: [],

    articlesTypeStatistics: [],
    typeStatisticsLabels: [],

    articlesSourceStatistics: [],
    articlesSourceStatisticsLabels: [],

    imagesSourceStatistics: [],
    imagesSourceStatisticsLabels: [],

    articlesEntitiesStatistics: [],
    articlesAuthorsStatistics: [],
    imagesPhotographersStatistics: [],
}

const getters = {
    getArticlesDateStatistics(state) {
        return state.articlesDateStatistics;
    },
    getImagesDateStatistics(state) {
        return state.imagesDateStatistics;
    },
    getDateStatisticsLabels(state) {
        return state.dateStatisticsLabels;
    },

    getArticlesTopicStatistics(state) {
        return state.articlesTopicStatistics;
    },
    getTopicStatisticsLabels(state) {
        return state.topicStatisticsLabels;
    },
    
    getArticlesTypeStatistics(state) {
        return state.articlesTypeStatistics;
    },
    getTypeStatisticsLabels(state) {
        return state.typeStatisticsLabels;
    },

    getArticlesSourceStatistics(state) {
        return state.articlesSourceStatistics;
    },
    getArticlesSourceStatisticsLabels(state) {
        return state.articlesSourceStatisticsLabels;
    },

    getImagesSourceStatistics(state) {
        return state.imagesSourceStatistics;
    },
    getImagesSourceStatisticsLabels(state) {
        return state.imagesSourceStatisticsLabels;
    },

    getArticlesEntitiesStatistics(state) {
        return state.articlesEntitiesStatistics
    },
    getArticlesAuthorsStatistics(state) {
        return state.articlesAuthorsStatistics
    },
    getImagesPhotographersStatistics(state) {
        return state.imagesPhotographersStatistics
    }
}

const actions = {
    async fetchArticlesDateStatistics({commit}, data) {
        if (data.value && data.value != "") {
            await getArticlesDateStatistics(data).then(
                response => {
                    var result = response.data.payload.aggregations['lterms#date'].buckets
                    commit('SET_ARTICLES_DATE_STATISTCS', result);
                    commit('SET_DATE_STATISTCS_LABELS', result);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        } else {
            await getArticlesDateStatisticsNoValue(data).then(
                response => {
                    var result = response.data.payload.aggregations['lterms#date'].buckets
                    commit('SET_ARTICLES_DATE_STATISTCS', result);
                    commit('SET_DATE_STATISTCS_LABELS', result);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        }
    },
    async fetchImagesDateStatistics({commit}, data) {
        if (data.value && data.value != "") {
            await getImagesDateStatistics(data).then(
                response => {
                    commit('SET_IMAGES_DATE_STATISTCS', response.data.payload.aggregations['lterms#date'].buckets);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        } else {
            await getImagesDateStatisticsNoValue(data).then(
                response => {
                    commit('SET_IMAGES_DATE_STATISTCS', response.data.payload.aggregations['lterms#date'].buckets);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        }
    },
    async fetchArticlesTopicStatistics({commit}, data) {
        if (data.value && data.value != "") {
            await getArticlesTopicStatistics(data).then(
                response => {
                    var result = response.data.payload.aggregations['sterms#topicName.keyword'].buckets
                    commit('SET_ARTICLES_TOPIC_STATISTCS', result);
                    commit('SET_TOPIC_STATISTCS_LABELS', result);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        } else {
            await getArticlesTopicStatisticsNoValue(data).then(
                response => {
                    var result = response.data.payload.aggregations['sterms#topicName.keyword'].buckets
                    commit('SET_ARTICLES_TOPIC_STATISTCS', result);
                    commit('SET_TOPIC_STATISTCS_LABELS', result);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        }
    },
    async fetchArticlesEntitiesStatistics({commit}, data) {
        if (data.value && data.value != "") {
            await getArticlesEntitiesStatistics(data).then(
                response => {
                    commit('SET_ARTICLES_ENTITIES_STATISTCS', response.data.payload.aggregations['nested#entities']['sterms#entities.name'].buckets);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        } else {
            await getArticlesEntitiesStatisticsNoValue(data).then(
                response => {
                    commit('SET_ARTICLES_ENTITIES_STATISTCS', response.data.payload.aggregations['nested#entities']['sterms#entities.name'].buckets);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        }
    },
    async fetchArticlesAuthorsStatistics({commit}, data) {
        if (data.value && data.value != "") {
            await getArticlesAuthorsStatistics(data).then(
                response => {
                    commit('SET_ARTICLES_AUTHORS_STATISTCS', response.data.payload.aggregations['sterms#authorName.keyword'].buckets);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        } else {
            await getArticlesAuthorsStatisticsNoValue(data).then(
                response => {
                    commit('SET_ARTICLES_AUTHORS_STATISTCS', response.data.payload.aggregations['sterms#authorName.keyword'].buckets);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        }
    },
    async fetchImagesPhotographersStatistics({commit}, data) {
        if (data.value && data.value != "") {
            await getImagesPhotographersStatistics(data).then(
                response => {
                    commit('SET_IMAGES_PHOTOGRAPHERS_STATISTCS', response.data.payload.aggregations['sterms#photographerName.keyword'].buckets);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        } else {
            await getImagesPhotographersStatisticsNoValue(data).then(
                response => {
                    commit('SET_IMAGES_PHOTOGRAPHERS_STATISTCS', response.data.payload.aggregations['sterms#photographerName.keyword'].buckets);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        }
    },
    async fetchArticlesTypeStatistics({commit}, data) {
        if (data.value && data.value != "") {
            await getArticlesTypeStatistics(data).then(
                response => {
                    var result = response.data.payload.aggregations['sterms#typeName.keyword'].buckets
                    commit('SET_ARTICLES_TYPE_STATISTCS', result);
                    commit('SET_TYPE_STATISTCS_LABELS', result);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        } else {
            await getArticlesTypeStatisticsNoValue(data).then(
                response => {
                    var result = response.data.payload.aggregations['sterms#typeName.keyword'].buckets
                    commit('SET_ARTICLES_TYPE_STATISTCS', result);
                    commit('SET_TYPE_STATISTCS_LABELS', result);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        }
    },
    async fetchArticlesSourceStatistics({commit}, data) {
        if (data.value && data.value != "") {
            await getArticlesSourceStatistics(data).then(
                response => {
                    var result = response.data.payload.aggregations['sterms#sourceName.keyword'].buckets
                    commit('SET_ARTICLES_SOURCE_STATISTCS', result);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        } else {
            await getArticlesSourceStatisticsNoValue(data).then(
                response => {
                    var result = response.data.payload.aggregations['sterms#sourceName.keyword'].buckets
                    commit('SET_ARTICLES_SOURCE_STATISTCS', result);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        }
    },
    async fetchImagesSourceStatistics({commit}, data) {
        if (data.value && data.value != "") {
            await getImagesSourceStatistics(data).then(
                response => {
                    var result = response.data.payload.aggregations['sterms#sourceName.keyword'].buckets
                    commit('SET_IMAGES_SOURCE_STATISTCS', result);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        } else {
            await getImagesSourceStatisticsNoValue(data).then(
                response => {
                    var result = response.data.payload.aggregations['sterms#sourceName.keyword'].buckets
                    commit('SET_IMAGES_SOURCE_STATISTCS', result);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        }
    }
}

const mutations = {
    SET_ARTICLES_DATE_STATISTCS(state, data) {
        var list = data.map(article => {
            return {
                label: article.key_as_string,
                value: article.doc_count
            }
        })

        state.articlesDateStatistics = list;
    },

    SET_IMAGES_DATE_STATISTCS(state, data) {
        var list = data.map(image => {
            return {
                label: image.key_as_string,
                value: image.doc_count
            }
        })
        state.imagesDateStatistics = list;
    },

    SET_DATE_STATISTCS_LABELS(state, data) {
        var labels = data.map(article => article.key_as_string)
        state.dateStatisticsLabels = labels;
    },

    SET_ARTICLES_TOPIC_STATISTCS(state, data) {
        var labels = data.map(article => article.doc_count)
        state.articlesTopicStatistics = labels;
    },

    SET_TOPIC_STATISTCS_LABELS(state, data) {
        var labels = data.map(article => article.key)
        state.topicStatisticsLabels = labels;
    },

    SET_ARTICLES_TYPE_STATISTCS(state, data) {
        var labels = data.map(article => article.doc_count)
        state.articlesTypeStatistics = labels;
    },

    SET_TYPE_STATISTCS_LABELS(state, data) {
        var labels = data.map(article => article.key)
        state.typeStatisticsLabels = labels;
    },

    SET_ARTICLES_SOURCE_STATISTCS(state, data) {
        var count = data.map(article => article.doc_count)
        var labels = data.map(article => article.key)
        state.articlesSourceStatistics = count;
        state.articlesSourceStatisticsLabels = labels;
    },

    SET_IMAGES_SOURCE_STATISTCS(state, data) {
        var count = data.map(image => image.doc_count)
        var labels = data.map(image => image.key)
        state.imagesSourceStatistics = count;
        state.imagesSourceStatisticsLabels = labels;
    },

    SET_ARTICLES_ENTITIES_STATISTCS(state, data) {
        var entities = data.map(entity => {
            return {
                name: entity.key,
                value: entity.doc_count
            }
        })
        state.articlesEntitiesStatistics = entities;
    },

    SET_ARTICLES_AUTHORS_STATISTCS(state, data) {
        var entities = data.map(entity => {
            return {
                name: entity.key,
                value: entity.doc_count
            }
        })
        state.articlesAuthorsStatistics = entities;
    },

    SET_IMAGES_PHOTOGRAPHERS_STATISTCS(state, data) {
        var entities = data.map(entity => {
            return {
                name: entity.key,
                value: entity.doc_count
            }
        })
        state.imagesPhotographersStatistics = entities;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}