import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/photographers";

const getAllPhotographers = (data) => {
  return service.get(END_POINT + objToQuery(data));
};

const getPhotographerById = (id) => {
  return service.get(END_POINT + "/by-id/" + id);
};

const searchPhotographers = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const createPhotographer = (data) => {
  return service.post(END_POINT, data);
};

const updatePhotographer = (id, data) => {
  return service.put(END_POINT + "/" + id, data);
};

const deleteOnePhotographer = (id) => {
  return service.delete(END_POINT + "/hard-delete-one/" + id);
};

const deleteMultiplePhotographers = (data) => {
  return service.delete(END_POINT + "/hard-delete-multiple", {
    data: JSON.stringify({ ids: data.ids }),
  });
};

const mergePhotographers = (data) => {
  return service.post(
    END_POINT + "/merge",
    JSON.stringify({ mainId: data.mainId, otherIds: data.otherIds })
  );
};

export {
  getAllPhotographers,
  getPhotographerById,
  searchPhotographers,
  createPhotographer,
  updatePhotographer,
  deleteOnePhotographer,
  deleteMultiplePhotographers,
  mergePhotographers,
};
