/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllArticles,
  searchArticles,
  getArticleById,
  updateArticle,
  deleteArticle
} from "@services/ArticlesBackend.api";

const state = {
  searchResultArticles: [],
  searchTermArticle: "",
  articlesGlobalCount: 0,
  articlesLocalCount: 0,
  numberOfRows: 6
};

const getters = {
  getSearchResultArticles(state) {
    return state.searchResultArticles;
  },
  getSearchTermArticle(state) {
    return state.searchTermArticle;
  },
  getArticlesGlobalCount(state) {
    return state.articlesGlobalCount;
  },
  getArticlesLocalCount(state) {
    return state.articlesLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllArticles({ commit }, data) {
    data.loadingFunction(true);
    await getAllArticles(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_ARTICLE", "");
        commit("SET_SEARCH_RESULT_ARTICLES", response.data.payload);
        commit("SET_ARTICLES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_ARTICLES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async fetchSearchResultArticles({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_ARTICLE", data.term);
    await searchArticles(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_ARTICLES", response.data.payload);
        commit("SET_ARTICLES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_ARTICLES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getArticle({}, data) {
    let res = {};
    await getArticleById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updateArticle({ commit }, data) {
    const { id, ...toSend } = data;
    await updateArticle(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_ARTICLE", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deleteArticle({ commit }, data) {
      data.loadingFunction(true);
      await deleteArticle(data.articleId).then(
        (response) => {
          commit("DELETE_ARTICLE", response.data.payload.id);
          data.loadingFunction(false);
        },
        (err) => {
          data.loadingFunction(false);
          data.inCaseOfError(
            "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
          );
        }
      );
    },
};

const mutations = {
  SET_SEARCH_RESULT_ARTICLES(state, data) {
    state.searchResultArticles = data;
  },
  SET_SEARCH_TERM_ARTICLE(state, data) {
    state.searchTermArticle = data;
  },
  DELETE_ARTICLE(state, data) {
    const arr = [];
    state.searchResultArticles.forEach((element) => {
      if (!(parseInt(element.id) === parseInt(data)) ) {
        arr.push(element);
      }
    });
    state.searchResultArticles = arr;
  },
  UPDATE_ARTICLE(state, data) {
    let idx = state.searchResultArticles.findIndex((obj) => obj.id === data.id);
    state.searchResultArticles[idx] = data;
  },
  SET_ARTICLES_GLOBAL_COUNT(state, data) {
    state.articlesGlobalCount = data;
  },
  SET_ARTICLES_LOCAL_COUNT(state, data) {
    state.articlesLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
