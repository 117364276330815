/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllTypes,
  searchTypes,
  createType,
  deleteMultipleTypes,
  getTypeById,
  updateType,
  mergeTypes,
} from "@services/Types.api";

const state = {
  searchResultTypes: [],
  searchTermType: "",
  typesGlobalCount: 0,
  typesLocalCount: 0,
  numberOfRows: 5,
};

const getters = {
  getSearchResultTypes(state) {
    return state.searchResultTypes;
  },
  getSearchTermType(state) {
    return state.searchTermType;
  },
  getTypesGlobalCount(state) {
    return state.typesGlobalCount;
  },
  getTypesLocalCount(state) {
    return state.typesLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllTypes({ commit }, data) {
    data.loadingFunction(true);
    await getAllTypes(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_TYPE", "");
        commit("SET_SEARCH_RESULT_TYPES", response.data.payload);
        commit("SET_TYPES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_TYPES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async addType({ commit }, data) {
    await createType(data).then(
      (response) => {
        commit("ADD_TYPE", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },

  async fetchSearchResultTypes({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_TYPE", data.term);
    await searchTypes(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_TYPES", response.data.payload);
        commit("SET_TYPES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_TYPES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getType({}, data) {
    let res = {};
    await getTypeById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updateType({ commit }, data) {
    const { id, ...toSend } = data;
    await updateType(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_TYPE", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deleteType({ commit }, data) {
    data.loadingFunction(true);
    await deleteMultipleTypes(data).then(
      () => {
        commit("DELETE_TYPES", data.ids);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError(
          "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
        );
      }
    );
  },
  async mergeType({ commit }, data) {
    data.loadingFunction(true);
    await mergeTypes(data).then(
      () => {
        commit("DELETE_TYPES", data.otherIds);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError("هناك خطأ ما");
      }
    );
  },
};

const mutations = {
  SET_SEARCH_RESULT_TYPES(state, data) {
    state.searchResultTypes = data;
  },
  SET_SEARCH_TERM_TYPE(state, data) {
    state.searchTermType = data;
  },
  ADD_TYPE(state, data) {
    state.searchResultTypes.push(data);
  },
  DELETE_TYPES(state, data) {
    const arr = [];
    state.searchResultTypes.forEach((element) => {
      if (!data.includes(element.id)) {
        arr.push(element);
      }
    });
    state.searchResultTypes = arr;
  },
  UPDATE_TYPE(state, data) {
    let idx = state.searchResultTypes.findIndex((obj) => obj.id === data.id);
    state.searchResultTypes[idx] = data;
  },
  SET_TYPES_GLOBAL_COUNT(state, data) {
    state.typesGlobalCount = data;
  },
  SET_TYPES_LOCAL_COUNT(state, data) {
    state.typesLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
