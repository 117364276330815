let SAFIRJWTTOKEN = 'safir-jwt-token'
let SAFIRJWTUSER = 'safir-user'
let initSafirJwtToken = localStorage[SAFIRJWTTOKEN]
let initSafirUser = null

try {
  initSafirUser = localStorage[SAFIRJWTUSER] ? JSON.parse(localStorage[SAFIRJWTUSER]) : null
} catch(e) {
  initSafirUser = null
}

const plugin = store => {
  store.subscribe((mutation) => {
    switch (mutation.type) {
      case 'auth/setToken':
        localStorage[SAFIRJWTTOKEN] = mutation.payload
        break
      case 'auth/resetToken':
        localStorage.removeItem(SAFIRJWTTOKEN)
        break
      case 'auth/setUser':
        localStorage[SAFIRJWTUSER] = JSON.stringify(mutation.payload)
        break
      case 'auth/resetUser':
        localStorage.removeItem(SAFIRJWTUSER)
        break
    }
  })
}

export default plugin
export { initSafirJwtToken, initSafirUser }
