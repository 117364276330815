import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/topics";

const getAllTopics = (data) => {
  return service.get(END_POINT + objToQuery(data));
};

const getTopicById = (id) => {
  return service.get(END_POINT + "/by-id/" + id);
};

const searchTopics = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const createTopic = (data) => {
  return service.post(END_POINT, data);
};

const updateTopic = (id, data) => {
  return service.put(END_POINT + "/" + id, data);
};

const deleteOneTopic = (id) => {
  return service.delete(END_POINT + "/hard-delete-one/" + id);
};

const deleteMultipleTopics = (data) => {
  return service.delete(END_POINT + "/hard-delete-multiple", {
    data: JSON.stringify({ ids: data.ids }),
  });
};

const mergeTopics = (data) => {
  return service.post(
    END_POINT + "/merge",
    JSON.stringify({ mainId: data.mainId, otherIds: data.otherIds })
  );
};

export {
  getAllTopics,
  getTopicById,
  searchTopics,
  createTopic,
  updateTopic,
  deleteOneTopic,
  deleteMultipleTopics,
  mergeTopics,
};
