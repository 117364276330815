import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/authors";

const getAllAuthors = (data) => {
  return service.get(END_POINT + objToQuery(data));
};

const getAuthorById = (id) => {
  return service.get(END_POINT + "/by-id/" + id);
};

const searchAuthors = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const createAuthor = (data) => {
  return service.post(END_POINT, data);
};

const updateAuthor = (id, data) => {
  return service.put(END_POINT + "/" + id, data);
};

const deleteOneAuthor = (id) => {
  return service.delete(END_POINT + "/hard-delete-one/" + id);
};

const deleteMultipleAuthors = (data) => {
  return service.delete(END_POINT + "/hard-delete-multiple", {
    data: JSON.stringify({ ids: data.ids }),
  });
};

const mergeAuthors = (data) => {
  return service.post(
    END_POINT + "/merge",
    JSON.stringify({ mainId: data.mainId, otherIds: data.otherIds })
  );
};

const getArticleCount = (id) => {
  return service.get(
    END_POINT + "/article-count/by-id/" + id);
}

export {
  getAllAuthors,
  getAuthorById,
  searchAuthors,
  createAuthor,
  updateAuthor,
  deleteOneAuthor,
  deleteMultipleAuthors,
  mergeAuthors,
  getArticleCount
};
