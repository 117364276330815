/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllUserQueries,
  searchUserQueries,
  createUserQuery,
  deleteMultipleUserQueries,
  getUserQueryById,
  updateUserQuery,
  mergeUserQueries,
} from "@services/UserQueries.api";

const state = {
  searchResultUserQueries: [],
  searchTermUserQuery: "",
  userQueriesGlobalCount: 0,
  userQueriesLocalCount: 0,
  numberOfRows: 5,
};

const getters = {
  getSearchResultUserQueries(state) {
    return state.searchResultUserQueries;
  },
  getSearchTermUserQuery(state) {
    return state.searchTermUserQuery;
  },
  getUserQueriesGlobalCount(state) {
    return state.userQueriesGlobalCount;
  },
  getUserQueriesLocalCount(state) {
    return state.userQueriesLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllUserQueries({ commit }, data) {
    data.loadingFunction(true);
    await getAllUserQueries(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_USERQUERY", "");
        commit("SET_SEARCH_RESULT_USERQUERIES", response.data.payload);
        commit("SET_USERQUERIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_USERQUERIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async addUserQuery({ commit }, data) {
    await createUserQuery(data).then(
      (response) => {
        commit("ADD_USERQUERY", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },

  async fetchSearchResultUserQueries({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_USERQUERY", data.term);
    await searchUserQueries(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_USERQUERIES", response.data.payload);
        commit("SET_USERQUERIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_USERQUERIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getUserQuery({}, data) {
    let res = {};
    await getUserQueryById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updateUserQuery({ commit }, data) {
    const { id, ...toSend } = data;
    await updateUserQuery(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_USERQUERY", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deleteUserQuery({ commit }, data) {
    data.loadingFunction(true);
    await deleteMultipleUserQueries(data).then(
      () => {
        commit("DELETE_USERQUERIES", data.ids);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError(
          "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
        );
      }
    );
  },
  async mergeUserQuery({ commit }, data) {
    data.loadingFunction(true);
    await mergeUserQueries(data).then(
      () => {
        commit("DELETE_USERQUERIES", data.otherIds);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError("هناك خطأ ما");
      }
    );
  },
};

const mutations = {
  SET_SEARCH_RESULT_USERQUERIES(state, data) {
    state.searchResultUserQueries = data;
  },
  SET_SEARCH_TERM_USERQUERY(state, data) {
    state.searchTermUserQuery = data;
  },
  ADD_USERQUERY(state, data) {
    state.searchResultUserQueries.push(data);
  },
  DELETE_USERQUERIES(state, data) {
    const arr = [];
    state.searchResultUserQueries.forEach((element) => {
      if (!data.includes(element.id)) {
        arr.push(element);
      }
    });
    state.searchResultUserQueries = arr;
  },
  UPDATE_USERQUERY(state, data) {
    let idx = state.searchResultUserQueries.findIndex(
      (obj) => obj.id === data.id
    );
    state.searchResultUserQueries[idx] = data;
  },
  SET_USERQUERIES_GLOBAL_COUNT(state, data) {
    state.userQueriesGlobalCount = data;
  },
  SET_USERQUERIES_LOCAL_COUNT(state, data) {
    state.userQueriesLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
