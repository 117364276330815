import { getSearchResultArticles, getSearchResultArticlesNoValue } from '@services/Articles.api'

const state = {
    searchResultArticles: [],
    articlesGlobalCount: 0,
    articlesLocalCount: 0
}

const getters = {
    getSearchResultArticles(state) {
        return state.searchResultArticles;
    },
    getArticlesGlobalCount(state) {
        return state.articlesGlobalCount;
    },
    getArticlesLocalCount(state) {
        return state.articlesLocalCount;
    }
}

const actions = {
    async fetchSearchResultArticles({commit}, data) {

        if (data.value && data.value != "") {
            await getSearchResultArticles(data).then(
                response => {
                    commit('SET_SEARCH_RESULT_ARTICLES', response.data.payload);
                    commit('SET_ARTICLES_GLOBAL_COUNT', response.data.globalCount);
                    commit('SET_ARTICLES_LOCAL_COUNT', response.data.localCount);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        } else {
            await getSearchResultArticlesNoValue(data).then(
                response => {
                    commit('SET_SEARCH_RESULT_ARTICLES', response.data.payload);
                    commit('SET_ARTICLES_GLOBAL_COUNT', response.data.globalCount);
                    commit('SET_ARTICLES_LOCAL_COUNT', response.data.localCount);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        }
    }
}

const mutations = {
    SET_SEARCH_RESULT_ARTICLES(state, data) {
        state.searchResultArticles = data;
    },

    SET_ARTICLES_GLOBAL_COUNT(state, data) {
        state.articlesGlobalCount = data;
    },

    SET_ARTICLES_LOCAL_COUNT(state, data) {
        state.articlesLocalCount = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}