import service from './Service';

const END_POINT = '/entities';

const getFilterOptions = (type, value) => { 
    return service.get(END_POINT + '/' + type + '/basic-search/'+ value + '?pageSize=10&pageNumber=1',  { useCache: true })
};

const bookmarkQuery = (data) => { 
    return service.post(END_POINT + '/users/queries', data)
};

const unBookmarkQuery = (data) => { 
    return service.delete(END_POINT + '/users/' + data.userId + '/queries/' + data.name)
};

export {
    getFilterOptions,
    bookmarkQuery,
    unBookmarkQuery
}