import Vue from 'vue'
import axios from 'axios'
import VueCookie from 'vue-cookie'
import { initSafirJwtToken, initSafirUser } from '../plugins/localStorage'
import config from '@/helpers/config'

Vue.use(VueCookie)

const state = {
  token: initSafirJwtToken,
  user: initSafirUser,
  isAdmin: false,
}

const mutations = {
  setToken (state, token) {
    state.token = token
  },
  resetToken (state) {
    state.token = ''
  },
  setUser (state, user) {
    state.user = user
  },
  resetUser (state) {
    state.user = {}
  },
  setAdmin (state) {
    state.isAdmin = true;
  },
  setNonAdmin(state) {
    state.isAdmin = false;
  }
}

const actions = {
  async loginFrontend ({ commit }, user) {
    const endpoint = config.apiUrl + '/entities/users/login-frontend'
    let data;
    try {
      data = await axios.post(endpoint, user)  
      
      if (data.data.message === "New Email Sent! Success!") {
        return {needsActivation: true, validAccount: true}
      } else {
        commit('setUser', data.data.payload.user)
        commit('setToken', data.data.payload.token)
        return {needsActivation: false, validAccount: true}
      }
    } catch(err) {
      return {needsActivation: false, validAccount: false}
    } 
  },
  async loginBackend({ commit }, user) {
    const endpoint = config.apiUrl + '/entities/users/login-backend'
    const { data } = await axios.post(endpoint, user)
    commit('setUser', data.payload.user)
    commit('setToken', data.payload.token)
    commit('setAdmin');
  },
  async register({ commit }, user) {
    const endpoint = config.apiUrl + '/entities/users'
    await axios.post(endpoint, user)
    commit('resetToken')
    commit('resetUser')
    Vue.cookie.delete('safir-cookie-jwt-token')
    Vue.cookie.delete('safir-cookie-jwt-user')
  },
  async activate({ commit }, user) {
    const endpoint = config.apiUrl + '/entities/users/activate'
    await axios.post(endpoint, user)
    commit('resetToken')
    commit('resetUser')
    Vue.cookie.delete('safir-cookie-jwt-token')
    Vue.cookie.delete('safir-cookie-jwt-user')
  },
  async forgot({ commit }, user) {
    const endpoint = config.apiUrl + '/entities/users/forgot'
    await axios.post(endpoint, user)
    commit('resetToken')
    commit('resetUser')
    Vue.cookie.delete('safir-cookie-jwt-token')
    Vue.cookie.delete('safir-cookie-jwt-user')
  },
  async reset({ commit }, user) {
    const endpoint = config.apiUrl + '/entities/users/reset'
    await axios.post(endpoint, user)
    commit('resetToken')
    commit('resetUser')
    Vue.cookie.delete('safir-cookie-jwt-token')
    Vue.cookie.delete('safir-cookie-jwt-user')
  },
  logout ({ commit }) {
    commit('resetToken')
    commit('resetUser')
    Vue.cookie.delete('safir-cookie-jwt-token')
    Vue.cookie.delete('safir-cookie-jwt-user')
  },
  logoutBackend ({ commit }) {
    commit('resetToken')
    commit('resetUser')
    commit('setNonAdmin')
    Vue.cookie.delete('safir-cookie-jwt-token')
    Vue.cookie.delete('safir-cookie-jwt-user')
  },
}

const getters = {
  isLoggedIn (state) {
    if (!state.token) {
      return false
    } else {
      return true
    }
  },
  currentUser (state) {
    if (!state.token) {
      return null
    } else {
      return state.user
    }
  },
  currentToken (state) {
    if (!state.token) {
      return null
    } else {
      return state.token
    }
  },
  isAdmin (state) {
    return state.isAdmin;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
