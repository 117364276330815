export default {
    name:"TheFooter",

    data () {
        return {
            dialog: false,
            dialogAbout: false
        }
    },

    methods: {
        openTermsAndConditionsPopup() {
            this.dialog = true
        },
        
        openAboutCompany() {
            this.dialogAbout = true
        },

        closeTermsAndConditionsPopup() {
            this.dialog = false
        },
        
        closeAboutCompany() {
            this.dialogAbout = false
        }
    }
}