<template>
  <v-snackbar :timeout="20000" :top="false" :bottom="true" :right="true" :left="false" v-model="showMessage">
    {{ config.message }}
    <v-btn dark @click.native="remove" icon class="float-left"><v-icon>mdi-close</v-icon></v-btn>
  </v-snackbar>
</template>

<style>
  .v-snack__wrapper{
    background-color: rgba(77, 79, 92) !important;
    border-radius: 5px !important;
  }
</style>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('notification', ['config']),
    showMessage: {
      get: function () {
        return this.config.show
      },
      set: function () {
        this.notify({message: '', show: false})
      }
    }
  },
  methods: {
    ...mapMutations('notification', ['remove', 'notify'])
  }
}
</script>
