/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllThesauruses,
  searchThesauruses,
  createThesaurus,
  deleteMultipleThesauruses,
  getThesaurusById,
  updateThesaurus,
  mergeThesauruses,
} from "@services/Thesauruses.api";

const state = {
  searchResultThesauruses: [],
  searchTermThesaurus: "",
  thesaurusesGlobalCount: 0,
  thesaurusesLocalCount: 0,
  numberOfRows: 5,
};

const getters = {
  getSearchResultThesauruses(state) {
    return state.searchResultThesauruses;
  },
  getSearchTermThesaurus(state) {
    return state.searchTermThesaurus;
  },
  getThesaurusesGlobalCount(state) {
    return state.thesaurusesGlobalCount;
  },
  getThesaurusesLocalCount(state) {
    return state.thesaurusesLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllThesauruses({ commit }, data) {
    data.loadingFunction(true);
    await getAllThesauruses(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_THESAURUS", "");
        commit("SET_SEARCH_RESULT_THESAURUSES", response.data.payload);
        commit("SET_THESAURUSES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_THESAURUSES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async addThesaurus({ commit }, data) {
    await createThesaurus(data).then(
      (response) => {
        commit("ADD_THESAURUS", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },

  async fetchSearchResultThesauruses({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_THESAURUS", data.term);
    await searchThesauruses(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_THESAURUSES", response.data.payload);
        commit("SET_THESAURUSES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_THESAURUSES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getThesaurus({}, data) {
    let res = {};
    await getThesaurusById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updateThesaurus({ commit }, data) {
    const { id, ...toSend } = data;
    await updateThesaurus(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_THESAURUS", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deleteThesaurus({ commit }, data) {
    data.loadingFunction(true);
    await deleteMultipleThesauruses(data).then(
      () => {
        commit("DELETE_THESAURUSES", data.ids);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError(
          "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
        );
      }
    );
  },
  async mergeThesaurus({ commit }, data) {
    data.loadingFunction(true);
    await mergeThesauruses(data).then(
      () => {
        commit("DELETE_THESAURUSES", data.otherIds);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError("هناك خطأ ما");
      }
    );
  },
};

const mutations = {
  SET_SEARCH_RESULT_THESAURUSES(state, data) {
    state.searchResultThesauruses = data;
  },
  SET_SEARCH_TERM_THESAURUS(state, data) {
    state.searchTermThesaurus = data;
  },
  ADD_THESAURUS(state, data) {
    state.searchResultThesauruses.push(data);
  },
  DELETE_THESAURUSES(state, data) {
    const arr = [];
    state.searchResultThesauruses.forEach((element) => {
      if (!data.includes(element.id)) {
        arr.push(element);
      }
    });
    state.searchResultThesauruses = arr;
  },
  UPDATE_THESAURUS(state, data) {
    let idx = state.searchResultThesauruses.findIndex(
      (obj) => obj.id === data.id
    );
    state.searchResultThesauruses[idx] = data;
  },
  SET_THESAURUSES_GLOBAL_COUNT(state, data) {
    state.thesaurusesGlobalCount = data;
  },
  SET_THESAURUSES_LOCAL_COUNT(state, data) {
    state.thesaurusesLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
