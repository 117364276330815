<template>
  <v-app>
    <vue-confirm-dialog></vue-confirm-dialog>
    <template>
      <the-header v-if="showHeaderFooter"></the-header>

      <v-main class="main-container">
        <div v-if="showHeaderFooter" class="main-container px-md-7 px-5">
          <router-view></router-view>
        </div>
        <div v-else class="main-container">
          <router-view></router-view>
        </div>
      </v-main>
      <Notifier></Notifier>

      <v-footer v-if="showHeaderFooter" class="mt-13" padless>
        <the-footer />
      </v-footer>
    </template>
  </v-app>
</template>

<script>
import Notifier from "./components/Notifier";
import TheFooter from "@shared/TheFooter/TheFooter.vue";
import TheHeader from "@shared/TheHeader/TheHeader.vue";

export default {
  name: "App",

  components: {
    Notifier,
    TheFooter,
    TheHeader,
  },

  data() {
    return {
      showHeaderFooter: true,
    };
  },

  methods: {
    setShowHeader() {
      if (
        this.$route.fullPath === "/" ||
        this.$route.matched[0].path === "/backend" ||
        this.$route.matched[0].path === "/backend/login"
      ) {
        this.showHeaderFooter = false;
      } else {
        this.showHeaderFooter = true;
      }
    },
  },

  watch: {
    $route() {
      this.setShowHeader();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  height: 100%;
}
</style>
