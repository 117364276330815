const state = {
    isPageLoading: false
}

const getters = {
    getIsPageLoading(state) {
        return state.isPageLoading;
    }
}

const mutations = {
    SET_IS_PAGE_LOADING(state, data) {
        state.isPageLoading = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}