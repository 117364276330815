import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import BasicSearch from "../views/BasicSearch.vue";
import ArticleView from "../views/ArticleView.vue";
import ImageView from "../views/ImageView.vue";
import NProgress from "vue-nprogress";
import store from "../store/index";

const requiresAdmin = true;

// Frontend Views

const TestZone = () =>
  import(
    /* webpackChunkName: "test-zone" */ "@views/frontend/TestZone/TestZone.vue"
  );

const Articles = () =>
  import(
    /* webpackChunkName: "articles" */ "@views/frontend/Articles/Articles.vue"
  );

const Images = () =>
  import(/* webpackChunkName: "images" */ "@views/frontend/Images/Images.vue");

const SignIn = () =>
  import(/* webpackChunkName: "SignIn" */ "@views/frontend/SignIn/SignIn.vue");

  const ForgotPassword = () =>
  import(/* webpackChunkName: "SignIn" */ "@views/frontend/ForgotPassword/ForgotPassword.vue");

const SignUp = () =>
  import(/* webpackChunkName: "SignUp" */ "@views/frontend/SignUp/SignUp.vue");

const Summary = () =>
  import(
    /* webpackChunkName: "Summary" */ "@views/frontend/Summary/Summary.vue"
  );

const Profile = () =>
  import(
    /* webpackChunkName: "Profile" */ "@views/frontend/Profile/Profile.vue"
  );

// Backend Views

const BackendHome = () =>
  import(
    /* webpackChunkName: "BackendLogin" */ "@views/backend/tabs/BackendHome/BackendHome.vue"
  );

const BackendLogin = () =>
  import(
    /* webpackChunkName: "BackendLogin" */ "@views/backend/forms/backendlogin/BackendLogin.vue"
  );

const BackendLayout = () =>
  import(
    /* webpackChunkName: "BackendLayout" */ "@views/backend/layout/BackendLayout/BackendLayout.vue"
  );

const BackendLanguage = () =>
  import(
    /* webpackChunkName: "BackendLanguage" */ "@views/backend/tabs/BackendLanguage/BackendLanguage.vue"
  );

const LanguageEdit = () =>
  import(
    /* webpackChunkName: "BackendLanguage" */ "@views/backend/forms/language/LanguageEdit.vue"
  );

const LanguageCreate = () =>
  import(
    /* webpackChunkName: "BackendLanguage" */ "@views/backend/forms/language/LanguageCreate.vue"
  );

const BackendAuthor = () =>
  import(
    /* webpackChunkName: "BackendAuthor" */ "@views/backend/tabs/BackendAuthor/BackendAuthor.vue"
  );

const AuthorEdit = () =>
  import(
    /* webpackChunkName: "BackendAuthor" */ "@views/backend/forms/author/AuthorEdit.vue"
  );

const AuthorCreate = () =>
  import(
    /* webpackChunkName: "BackendAuthor" */ "@views/backend/forms/author/AuthorCreate.vue"
  );

const BackendPhotographer = () =>
  import(
    /* webpackChunkName: "BackendPhotographer" */ "@views/backend/tabs/BackendPhotographer/BackendPhotographer.vue"
  );

const PhotographerEdit = () =>
  import(
    /* webpackChunkName: "BackendPhotographer" */ "@views/backend/forms/photographer/PhotographerEdit.vue"
  );

const PhotographerCreate = () =>
  import(
    /* webpackChunkName: "BackendPhotographer" */ "@views/backend/forms/photographer/PhotographerCreate.vue"
  );

const BackendCategory = () =>
  import(
    /* webpackChunkName: "BackendCategory" */ "@views/backend/tabs/BackendCategory/BackendCategory.vue"
  );

const CategoryEdit = () =>
  import(
    /* webpackChunkName: "BackendCategory" */ "@views/backend/forms/category/CategoryEdit.vue"
  );

const CategoryCreate = () =>
  import(
    /* webpackChunkName: "BackendCategory" */ "@views/backend/forms/category/CategoryCreate.vue"
  );

const BackendType = () =>
  import(
    /* webpackChunkName: "BackendCategory" */ "@views/backend/tabs/BackendType/BackendType.vue"
  );

const TypeEdit = () =>
  import(
    /* webpackChunkName: "BackendType" */ "@views/backend/forms/type/TypeEdit.vue"
  );

const TypeCreate = () =>
  import(
    /* webpackChunkName: "BackendType" */ "@views/backend/forms/type/TypeCreate.vue"
  );

const BackendTopic = () =>
  import(
    /* webpackChunkName: "BackendCategory" */ "@views/backend/tabs/BackendTopic/BackendTopic.vue"
  );

const TopicEdit = () =>
  import(
    /* webpackChunkName: "BackendTopic" */ "@views/backend/forms/topic/TopicEdit.vue"
  );

const TopicCreate = () =>
  import(
    /* webpackChunkName: "BackendTopic" */ "@views/backend/forms/topic/TopicCreate.vue"
  );

const BackendSource = () =>
  import(
    /* webpackChunkName: "BackendSource" */ "@views/backend/tabs/BackendSource/BackendSource.vue"
  );

const SourceEdit = () =>
  import(
    /* webpackChunkName: "BackendSource" */ "@views/backend/forms/source/SourceEdit.vue"
  );

const SourceCreate = () =>
  import(
    /* webpackChunkName: "BackendSource" */ "@views/backend/forms/source/SourceCreate.vue"
  );

const BackendUserQuery = () =>
  import(
    /* webpackChunkName: "BackendUserQuery" */ "@views/backend/tabs/BackendUserQuery/BackendUserQuery.vue"
  );

const UserQueryEdit = () =>
  import(
    /* webpackChunkName: "BackendUserQuery" */ "@views/backend/forms/userQuery/UserQueryEdit.vue"
  );

const UserQueryCreate = () =>
  import(
    /* webpackChunkName: "BackendUserQuery" */ "@views/backend/forms/userQuery/UserQueryCreate.vue"
  );

const BackendEntity = () =>
  import(
    /* webpackChunkName: "BackendEntity" */ "@views/backend/tabs/BackendEntity/BackendEntity.vue"
  );

const EntityEdit = () =>
  import(
    /* webpackChunkName: "BackendEntity" */ "@views/backend/forms/entity/EntityEdit.vue"
  );

const EntityCreate = () =>
  import(
    /* webpackChunkName: "BackendEntity" */ "@views/backend/forms/entity/EntityCreate.vue"
  );

const BackendThesaurus = () =>
  import(
    /* webpackChunkName: "BackendThesaurus" */ "@views/backend/tabs/BackendThesaurus/BackendThesaurus.vue"
  );

const ThesaurusEdit = () =>
  import(
    /* webpackChunkName: "BackendThesaurus" */ "@views/backend/forms/thesaurus/ThesaurusEdit.vue"
  );

const ThesaurusCreate = () =>
  import(
    /* webpackChunkName: "BackendThesaurus" */ "@views/backend/forms/thesaurus/ThesaurusCreate.vue"
  );

const BackendUser = () =>
  import(
    /* webpackChunkName: "BackendUser" */ "@views/backend/tabs/BackendUser/BackendUser.vue"
  );

const UserEdit = () =>
  import(
    /* webpackChunkName: "BackendUser" */ "@views/backend/forms/user/UserEdit.vue"
  );

const UserCreate = () =>
  import(
    /* webpackChunkName: "BackendUser" */ "@views/backend/forms/user/UserCreate.vue"
  );

const BackendImage = () =>
  import(
    /* webpackChunkName: "BackendImage" */ "@views/backend/tabs/BackendImage/BackendImage.vue"
  );

const ImageEdit = () =>
  import(
    /* webpackChunkName: "BackendUser" */ "@views/backend/forms/image/ImageEdit.vue"
  );

const BackendArticle = () =>
  import(
    /* webpackChunkName: "BackendArticle" */ "@views/backend/tabs/BackendArticle/BackendArticle.vue"
  );

const ArticleEdit = () =>
  import(
    /* webpackChunkName: "BackendUser" */ "@views/backend/forms/article/ArticleEdit.vue"
  );

const BackendQueryStats = () =>
  import(
    /* webpackChunkName: "BackendUser" */ "@views/backend/tabs/BackendQueryStats/BackendQueryStats.vue"
  );

Vue.use(VueRouter);
Vue.use(NProgress);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/basic-search",
    name: "BasicSearch",
    component: BasicSearch,
  },
  {
    path: "/article-view/:safirId",
    name: "ArticleView",
    component: ArticleView,
    props: true,
  },
  {
    path: "/image-view/:safirId",
    name: "ImageView",
    component: ImageView,
    props: true,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/testzone",
    name: "testzone",
    component: TestZone,
  },
  {
    path: "/articles",
    name: "articles",
    component: Articles,
    // props(route) {
    //     const props = { ...route.params }
    //     return props
    // },
  },
  {
    path: "/images",
    name: "images",
    component: Images,
  },
  {
    path: "/sign-in",
    name: "signin",
    component: SignIn,
  },
  {
    path: "/reset/:uuid",
    name: "forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/sign-in/:uuid",
    name: "signin",
    component: SignIn,
    props: true,
  },
  {
    path: "/sign-up",
    name: "signup",
    component: SignUp,
  },
  {
    path: "/summary",
    name: "summary",
    component: Summary,
    // props(route) {
    //     const props = { ...route.params }
    //     return props
    // },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  // Backend Routes
  {
    name: "BackendLogin",
    path: "/backend/login",
    component: BackendLogin,
  },
  {
    path: "/backend",
    component: BackendLayout,
    children: [
      {
        name: "BackendHome",
        path: "",
        component: BackendHome,
        meta: {
          requiresAdmin,
        },
      },
      {
        name: "BackendQueryStats",
        path: "query-stats",
        component: BackendQueryStats,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "إحصائيات المستخدمین", href: "BackendQueryStats" },
          ],
        },
      },
      // Languages
      {
        name: "BackendLanguage",
        path: "languages",
        component: BackendLanguage,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "اللغات", href: "BackendLanguage" },
          ],
        },
      },
      {
        name: "LanguageEdit",
        path: "languages/:id/edit",
        props: true,
        component: LanguageEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل اللغة" },
          ],
        },
      },
      {
        name: "LanguageCreate",
        path: "languages/create",
        component: LanguageCreate,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير‎", href: "BackendHome" },
            { name: "إضافة جديدة (اللغة)" },
          ],
        },
      },
      // Authors
      {
        name: "BackendAuthor",
        path: "authors",
        component: BackendAuthor,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "المؤلفون", href: "BackendAuthor" },
          ],
        },
      },
      {
        name: "AuthorEdit",
        path: "authors/:id/edit",
        props: true,
        component: AuthorEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل المؤلفون" },
          ],
        },
      },
      {
        name: "AuthorCreate",
        path: "authors/create",
        component: AuthorCreate,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير‎", href: "BackendHome" },
            { name: "إضافة جديدة (المؤلف)" },
          ],
        },
      },
      // Photographers
      {
        name: "BackendPhotographer",
        path: "photographers",
        component: BackendPhotographer,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "المصورون", href: "BackendPhotographer" },
          ],
        },
      },
      {
        name: "PhotographerEdit",
        path: "photographers/:id/edit",
        props: true,
        component: PhotographerEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل المصورون" },
          ],
        },
      },
      {
        name: "PhotographerCreate",
        path: "photographers/create",
        component: PhotographerCreate,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير‎", href: "BackendHome" },
            { name: "إضافة جديدة (المصور)" },
          ],
        },
      },
      // Categories
      {
        name: "BackendCategory",
        path: "categories",
        component: BackendCategory,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "الملاحق والإصدارات", href: "BackendCategory" },
          ],
        },
      },
      {
        name: "CategoryEdit",
        path: "categories/:id/edit",
        props: true,
        component: CategoryEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل الملاحق والإصدارات" },
          ],
        },
      },
      {
        name: "CategoryCreate",
        path: "categories/create",
        component: CategoryCreate,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير‎", href: "BackendHome" },
            { name: "إضافة جديدة (الملاحق والإصدارات)" },
          ],
        },
      },
      // Types
      {
        name: "BackendType",
        path: "types",
        component: BackendType,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "نوع المقالات", href: "BackendType" },
          ],
        },
      },
      {
        name: "TypeEdit",
        path: "types/:id/edit",
        props: true,
        component: TypeEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل نوع المقالات" },
          ],
        },
      },
      {
        name: "TypeCreate",
        path: "types/create",
        component: TypeCreate,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير‎", href: "BackendHome" },
            { name: "إضافة جديدة (نوع المقالات)" },
          ],
        },
      },
      // Topics
      {
        name: "BackendTopic",
        path: "topics",
        component: BackendTopic,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "التبویب", href: "BackendTopic" },
          ],
        },
      },
      {
        name: "TopicEdit",
        path: "topics/:id/edit",
        props: true,
        component: TopicEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل التبویب" },
          ],
        },
      },
      {
        name: "TopicCreate",
        path: "topics/create",
        component: TopicCreate,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير‎", href: "BackendHome" },
            { name: "إضافة جديدة (الموضوع)" },
          ],
        },
      },
      // Sources
      {
        name: "BackendSource",
        path: "sources",
        component: BackendSource,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "المصادر", href: "BackendSource" },
          ],
        },
      },
      {
        name: "SourceEdit",
        path: "sources/:id/edit",
        props: true,
        component: SourceEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل المصادر" },
          ],
        },
      },
      {
        name: "SourceCreate",
        path: "sources/create",
        component: SourceCreate,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير‎", href: "BackendHome" },
            { name: "إضافة جديدة (المصدر)" },
          ],
        },
      },
      // UserQueries
      {
        name: "BackendUserQuery",
        path: "user-queries",
        component: BackendUserQuery,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "أبحاث المستخدمین", href: "BackendUserQuery" },
          ],
        },
      },
      {
        name: "UserQueryEdit",
        path: "user-queries/:id/edit",
        props: true,
        component: UserQueryEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل أبحاث المستخدمین" },
          ],
        },
      },
      {
        name: "UserQueryCreate",
        path: "user-queries/create",
        component: UserQueryCreate,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير‎", href: "BackendHome" },
            { name: "إضافة جديدة أبحاث المستخدمین" },
          ],
        },
      },
      // Entities
      {
        name: "BackendEntity",
        path: "entities",
        component: BackendEntity,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "التسمیات", href: "BackendEntity" },
          ],
        },
      },
      {
        name: "EntityEdit",
        path: "entities/:id/edit",
        props: true,
        component: EntityEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل التسمية" },
          ],
        },
      },
      {
        name: "EntityCreate",
        path: "entities/create",
        component: EntityCreate,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير‎", href: "BackendHome" },
            { name: "إضافة جديدة (التسمية)" },
          ],
        },
      },
      // Thesaurus
      {
        name: "BackendThesaurus",
        path: "thesauruses",
        component: BackendThesaurus,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "المكانز", href: "BackendThesaurus" },
          ],
        },
      },
      {
        name: "ThesaurusEdit",
        path: "thesauruses/:id/edit",
        props: true,
        component: ThesaurusEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل المكانز" },
          ],
        },
      },
      {
        name: "ThesaurusCreate",
        path: "thesauruses/create",
        component: ThesaurusCreate,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير‎", href: "BackendHome" },
            { name: "إضافة جديدة (المكانز)" },
          ],
        },
      },
      // Users
      {
        name: "BackendUser",
        path: "users",
        component: BackendUser,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "المستخدمون", href: "BackendUser" },
          ],
        },
      },
      {
        name: "UserEdit",
        path: "users/:id/edit",
        props: true,
        component: UserEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل المستخدمون" },
          ],
        },
      },
      {
        name: "UserCreate",
        path: "user/create",
        component: UserCreate,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير‎", href: "BackendHome" },
            { name: "إضافة جديدة (المستخدمون)" },
          ],
        },
      },
      // Images
      {
        name: "BackendImage",
        path: "images",
        component: BackendImage,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "الصور", href: "BackendImage" },
          ],
        },
      },
      {
        name: "ImageEdit",
        path: "images/:id/edit",
        props: true,
        component: ImageEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل الصور" },
          ],
        },
      },
      // Articles
      {
        name: "BackendArticle",
        path: "articles",
        component: BackendArticle,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "المقالات", href: "BackendArticle" },
          ],
        },
      },
      {
        name: "ArticleEdit",
        path: "articles/:id/edit",
        props: true,
        component: ArticleEdit,
        meta: {
          requiresAdmin,
          breadcrumb: [
            { name: "أرشيف السفير", href: "BackendHome" },
            { name: "تعديل المقالات" },
          ],
        },
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  // mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    let isAdmin = store.state.auth.isAdmin;
    if (isAdmin) {
      next();
    } else {
      router.push({
        name: "BackendLogin",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});

export default router;
