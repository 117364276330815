const state = {
  config: {show: false, message: ''}
}

const mutations = {
  notify (state, config) {
    state.config = config
  },
  remove (state) {
    state.config.show = false
    state.config.message = ''
  }
}

const getters = {
  show: state => state.config.show,
  message: state => state.config.message,
  config: state => state.config
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
