/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllCategories,
  searchCategories,
  createCategory,
  deleteMultipleCategories,
  getCategoryById,
  updateCategory,
  mergeCategories,
} from "@services/Categories.api";

const state = {
  searchResultCategories: [],
  searchTermCategory: "",
  categoriesGlobalCount: 0,
  categoriesLocalCount: 0,
  numberOfRows: 5,
};

const getters = {
  getSearchResultCategories(state) {
    return state.searchResultCategories;
  },
  getSearchTermCategory(state) {
    return state.searchTermCategory;
  },
  getCategoriesGlobalCount(state) {
    return state.categoriesGlobalCount;
  },
  getCategoriesLocalCount(state) {
    return state.categoriesLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllCategories({ commit }, data) {
    data.loadingFunction(true);
    await getAllCategories(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_CATEGORY", "");
        commit("SET_SEARCH_RESULT_CATEGORIES", response.data.payload);
        commit("SET_CATEGORIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_CATEGORIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async addCategory({ commit }, data) {
    await createCategory(data).then(
      (response) => {
        commit("ADD_CATEGORY", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },

  async fetchSearchResultCategories({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_CATEGORY", data.term);
    await searchCategories(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_CATEGORIES", response.data.payload);
        commit("SET_CATEGORIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_CATEGORIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getCategory({}, data) {
    let res = {};
    await getCategoryById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updateCategory({ commit }, data) {
    const { id, ...toSend } = data;
    await updateCategory(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_CATEGORY", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deleteCategory({ commit }, data) {
    data.loadingFunction(true);
    await deleteMultipleCategories(data).then(
      () => {
        commit("DELETE_CATEGORIES", data.ids);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError(
          "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
        );
      }
    );
  },
  async mergeCategory({ commit }, data) {
    data.loadingFunction(true);
    await mergeCategories(data).then(
      () => {
        commit("DELETE_CATEGORIES", data.otherIds);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError("هناك خطأ ما");
      }
    );
  },
};

const mutations = {
  SET_SEARCH_RESULT_CATEGORIES(state, data) {
    state.searchResultCategories = data;
  },
  SET_SEARCH_TERM_CATEGORY(state, data) {
    state.searchTermCategory = data;
  },
  ADD_CATEGORY(state, data) {
    state.searchResultCategories.push(data);
  },
  DELETE_CATEGORIES(state, data) {
    const arr = [];
    state.searchResultCategories.forEach((element) => {
      if (!data.includes(element.id)) {
        arr.push(element);
      }
    });
    state.searchResultCategories = arr;
  },
  UPDATE_CATEGORY(state, data) {
    let idx = state.searchResultCategories.findIndex(
      (obj) => obj.id === data.id
    );
    state.searchResultCategories[idx] = data;
  },
  SET_CATEGORIES_GLOBAL_COUNT(state, data) {
    state.categoriesGlobalCount = data;
  },
  SET_CATEGORIES_LOCAL_COUNT(state, data) {
    state.categoriesLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
