import axios from 'axios'
import store from '../store'
import config from '@/helpers/config'

export default {
  basicSearch (context, payload) {
    let q = config.apiUrl + '/entities/' + payload.entity + '/basic-search/' + payload.val + payload.query
    if (config.cacheEnabled === false) {
      q = (q.includes('?') === false ? q + '?no_cache=' + (new Date()).getTime() : q)
      q = (q.includes('no_cache') === false ? q + '&no_cache=' + (new Date()).getTime() : q)
    }
    return axios.get(
      q,
      { headers: {'Authorization': 'Bearer ' + store.getters['auth/currentToken'], 'Content-Type': 'application/json; charset=UTF-8'} }
    )
  },
  basicElasticSearch (context, payload) {
    let q = config.apiUrl + '/entities/' + payload.entity + '/basic-elastic-search/' + payload.val + payload.query
    if (config.cacheEnabled === false) {
      q = (q.includes('?') === false ? q + '?no_cache=' + (new Date()).getTime() : q)
      q = (q.includes('no_cache') === false ? q + '&no_cache=' + (new Date()).getTime() : q)
    }
    return axios.get(
      q,
      { headers: {'Authorization': 'Bearer ' + store.getters['auth/currentToken'], 'Content-Type': 'application/json; charset=UTF-8'} }
    )
  },
  elasticSearchBySafirId (context, payload) {
    let q = config.apiUrl + '/entities/' + payload.entity + '/elastic-search-by-safir-id/' + payload.val + payload.query
    if (config.cacheEnabled === false) {
      q = (q.includes('?') === false ? q + '?no_cache=' + (new Date()).getTime() : q)
      q = (q.includes('no_cache') === false ? q + '&no_cache=' + (new Date()).getTime() : q)
    }
    return axios.get(
      q,
      { headers: {'Authorization': 'Bearer ' + store.getters['auth/currentToken'], 'Content-Type': 'application/json; charset=UTF-8'} }
    )
  }
}
