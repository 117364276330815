/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllSources,
  searchSources,
  createSource,
  deleteMultipleSources,
  getSourceById,
  updateSource,
  mergeSources,
} from "@services/Sources.api";

const state = {
  searchResultSources: [],
  searchTermSource: "",
  sourcesGlobalCount: 0,
  sourcesLocalCount: 0,
  numberOfRows: 5,
};

const getters = {
  getSearchResultSources(state) {
    return state.searchResultSources;
  },
  getSearchTermSource(state) {
    return state.searchTermSource;
  },
  getSourcesGlobalCount(state) {
    return state.sourcesGlobalCount;
  },
  getSourcesLocalCount(state) {
    return state.sourcesLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllSources({ commit }, data) {
    data.loadingFunction(true);
    await getAllSources(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_SOURCE", "");
        commit("SET_SEARCH_RESULT_SOURCES", response.data.payload);
        commit("SET_SOURCES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_SOURCES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async addSource({ commit }, data) {
    await createSource(data).then(
      (response) => {
        commit("ADD_SOURCE", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },

  async fetchSearchResultSources({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_SOURCE", data.term);
    await searchSources(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_SOURCES", response.data.payload);
        commit("SET_SOURCES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_SOURCES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getSource({}, data) {
    let res = {};
    await getSourceById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updateSource({ commit }, data) {
    const { id, ...toSend } = data;
    await updateSource(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_SOURCE", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deleteSource({ commit }, data) {
    data.loadingFunction(true);
    await deleteMultipleSources(data).then(
      () => {
        commit("DELETE_SOURCES", data.ids);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError(
          "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
        );
      }
    );
  },
  async mergeSource({ commit }, data) {
    data.loadingFunction(true);
    await mergeSources(data).then(
      () => {
        commit("DELETE_SOURCES", data.otherIds);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError("هناك خطأ ما");
      }
    );
  },
};

const mutations = {
  SET_SEARCH_RESULT_SOURCES(state, data) {
    state.searchResultSources = data;
  },
  SET_SEARCH_TERM_SOURCE(state, data) {
    state.searchTermSource = data;
  },
  ADD_SOURCE(state, data) {
    state.searchResultSources.push(data);
  },
  DELETE_SOURCES(state, data) {
    const arr = [];
    state.searchResultSources.forEach((element) => {
      if (!data.includes(element.id)) {
        arr.push(element);
      }
    });
    state.searchResultSources = arr;
  },
  UPDATE_SOURCE(state, data) {
    let idx = state.searchResultSources.findIndex((obj) => obj.id === data.id);
    state.searchResultSources[idx] = data;
  },
  SET_SOURCES_GLOBAL_COUNT(state, data) {
    state.sourcesGlobalCount = data;
  },
  SET_SOURCES_LOCAL_COUNT(state, data) {
    state.sourcesLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
