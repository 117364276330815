var removeByAttr = function (array, attribute, value) {
    var i = array.length;
    while(i--){
        if (array[i] 
            && Object.hasOwnProperty.call(array[i], attribute) 
            && (arguments.length > 2 && array[i][attribute] === value ) ){ 

            array.splice(i,1);
        }
    }
    return array;
}

var mergeArrayObjects = function(object1, object2) {
    var mergedObject = {...object1}
    for (const key in object2) {
        if (Object.hasOwnProperty.call(object2, key)) {
            if (Object.hasOwnProperty.call(object1, key)) {
                mergedObject[key] = [...object1[key]]

                if (Array.isArray(object2[key])) {
                    for (let i = 0; i < object2[key].length; i++) {
                        if (!object1[key].includes(object2[key][i])) {
                            mergedObject[key].push(object2[key][i])
                        }
                    }
                } else {
                    if (!object1[key].includes(object2[key])) {
                        mergedObject[key].push(object2[key])
                    }
                }
            } else {
                mergedObject[key] = object2[key]
            }
        }
    }
    return mergedObject
}

var numToArabic = function(number){
    number = number + ""
    var id= ['۰','۱','۲','۳','٤','۵','٦','۷','۸','۹'];
    return number.replace(/[0-9]/g, function(digit) {
        return id[+digit]
    });
}

var getArabicMonth = function(monthNumber) {
    var months = [ "كانون الثاني", "شباط", "آذار", "نيسان", "أيار", "حزيران", 
           "تموز", "آب", "أيلول", "تشرين الأول", "تشرين الثاني", "كانون الأول" ];

    return months[monthNumber - 1];
}

var isObjectEmpty = function(object) {
    return object && Object.keys(object).length === 0 && object.constructor === Object ? true : false
}

var getEntitiesList = function(entities) {
    var entitiesMap = {
        SUBJECT: "الموضوع",
        LOCATION: "المكان",
        ORGANIZATION: "المؤسسات",
        PERSON: "الأشخاص",
        SOURCE: "المصدر",
        AUTHOR: "المؤلف",
        PHOTOGRAPHER: "المصور",
        CATEGORY: "النوع"
    }
    if (entities) {
        var object = {}
        for (let i = 0; i < entities.length; i++) {
            var type = entities[i].type
            var mappedType = entitiesMap[type]

            if (!Object.hasOwnProperty.call(object, mappedType)) {
                object[mappedType] = []
            }
            object[mappedType].push(entities[i].name)
        }

        return object
    }
}

var removeUndefined = function(string) {
    if (string) {
        return string === "UNKNOWN"? '' : string
    } 
    return ''
}

var queryStringToObject = function(data) {
    var pairs = data.split('&'); 
    var queryObject = {}

    for (let i = 0; i < pairs.length; i++) {
        var key = pairs[i].split("=")[0]
        var value = pairs[i].split("=")[1]

        if (!Object.prototype.hasOwnProperty.call(queryObject, key)) {
            queryObject[key] = []
        } 

        queryObject[key].push(value)
    }

   return queryObject
}

const arrayToQueryChain = (arr, keyName) => {
    let str = '';
    arr.forEach((el, idx) => {
        if (idx === 0) {
            let toAppend = keyName + "=" + el;
            str += toAppend;
        } else {
            let toAppend = '&' + keyName + "=" + el;
            str += toAppend;
        }
    });
    return str;
}

const objToQuery = (obj) => {
    if (obj) {
        let str = "?";
        return str + new URLSearchParams(obj).toString();
    }
};

export {
    removeByAttr,
    mergeArrayObjects,
    numToArabic,
    getArabicMonth,
    getEntitiesList,
    queryStringToObject,
    removeUndefined,
    isObjectEmpty,
    arrayToQueryChain,
    objToQuery
}