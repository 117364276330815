import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/entities";

const getAllEntities = (data) => {
  return service.get(END_POINT + objToQuery(data));
};

const getAllEntitiesByType = (entityType, data) => {
  return service.get(END_POINT + "/by-type/" + entityType + objToQuery(data));
};

const getEntityById = (id) => {
  return service.get(END_POINT + "/by-id/" + id);
};

const searchEntities = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const searchEntitiesByOrganizations = (searchTerm, query) => {
  return service.get(
    "/entities/organizations/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const searchEntitiesByPeople = (searchTerm, query) => {
  return service.get(
    "/entities/people/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const searchEntitiesByLocations = (searchTerm, query) => {
  return service.get(
    "/entities/locations/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const searchEntitiesBySubjects = (searchTerm, query) => {
  return service.get(
    "/entities/subjects/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const searchEntitiesByUnknowns = (searchTerm, query) => {
  return service.get(
    "/entities/unknowns/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const createEntity = (data) => {
  return service.post(END_POINT, data);
};

const updateEntity = (id, data) => {
  return service.put(END_POINT + "/" + id, data);
};

const deleteMultipleEntities = (data) => {
  return service.delete(END_POINT + "/hard-delete-multiple", {
    data: JSON.stringify({ ids: data.ids }),
  });
};

const mergeEntities = (data) => {
  return service.post(
    END_POINT + "/merge",
    JSON.stringify({ mainId: data.mainId, otherIds: data.otherIds })
  );
};

export {
  getAllEntities,
  getAllEntitiesByType,
  getEntityById,
  searchEntities,
  createEntity,
  updateEntity,
  deleteMultipleEntities,
  mergeEntities,
  searchEntitiesByLocations,
  searchEntitiesBySubjects,
  searchEntitiesByOrganizations,
  searchEntitiesByUnknowns,
  searchEntitiesByPeople,
};
