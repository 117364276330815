/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllLanguages,
  searchLanguages,
  createLanguage,
  deleteMultipleLanguages,
  getLanguageById,
  updateLanguage,
  mergeLanguages,
} from "@services/Languages.api";

const state = {
  searchResultLanguages: [],
  searchTermLanguage: "",
  languagesGlobalCount: 0,
  languagesLocalCount: 0,
  numberOfRows: 5,
};

const getters = {
  getSearchResultLanguages(state) {
    return state.searchResultLanguages;
  },
  getSearchTermLanguage(state) {
    return state.searchTermLanguage;
  },
  getLanguagesGlobalCount(state) {
    return state.languagesGlobalCount;
  },
  getLanguagesLocalCount(state) {
    return state.languagesLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllLanguages({ commit }, data) {
    data.loadingFunction(true);
    await getAllLanguages(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_LANGUAGE", "");
        commit("SET_SEARCH_RESULT_LANGUAGES", response.data.payload);
        commit("SET_LANGUAGES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_LANGUAGES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async addLanguage({ commit }, data) {
    await createLanguage(data).then(
      (response) => {
        commit("ADD_LANGUAGE", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },

  async fetchSearchResultLanguages({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_LANGUAGE", data.term);
    await searchLanguages(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_LANGUAGES", response.data.payload);
        commit("SET_LANGUAGES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_LANGUAGES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getLanguage({}, data) {
    let res = {};
    await getLanguageById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updateLanguage({ commit }, data) {
    const { id, ...toSend } = data;
    await updateLanguage(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_LANGUAGE", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deleteLanguage({ commit }, data) {
    data.loadingFunction(true);
    await deleteMultipleLanguages(data).then(
      () => {
        commit("DELETE_LANGUAGES", data.ids);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError(
          "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
        );
      }
    );
  },
  async mergeLanguage({ commit }, data) {
    data.loadingFunction(true);
    await mergeLanguages(data).then(
      () => {
        commit("DELETE_LANGUAGES", data.otherIds);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError("هناك خطأ ما");
      }
    );
  },
};

const mutations = {
  SET_SEARCH_RESULT_LANGUAGES(state, data) {
    state.searchResultLanguages = data;
  },
  SET_SEARCH_TERM_LANGUAGE(state, data) {
    state.searchTermLanguage = data;
  },
  ADD_LANGUAGE(state, data) {
    state.searchResultLanguages.push(data);
  },
  DELETE_LANGUAGES(state, data) {
    const arr = [];
    state.searchResultLanguages.forEach((element) => {
      if (!data.includes(element.id)) {
        arr.push(element);
      }
    });
    state.searchResultLanguages = arr;
  },
  UPDATE_LANGUAGE(state, data) {
    let idx = state.searchResultLanguages.findIndex(
      (obj) => obj.id === data.id
    );
    state.searchResultLanguages[idx] = data;
  },
  SET_LANGUAGES_GLOBAL_COUNT(state, data) {
    state.languagesGlobalCount = data;
  },
  SET_LANGUAGES_LOCAL_COUNT(state, data) {
    state.languagesLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
