import { getSearchResultImages, getSearchResultImagesNoValue } from '@services/Images.api'
 
const state = {
    searchResultImages: [],
    imagesGlobalCount: 0,
    imagesLocalCount: 0,
}

const getters = {
    getSearchResultImages(state) {
        return state.searchResultImages;
    },
    getImagesGlobalCount(state) {
        return state.imagesGlobalCount;
    },
    getImagesLocalCount(state) {
        return state.imagesLocalCount;
    }
}

const actions = {
    async fetchSearchResultImages({commit}, data) {
        if (data.value && data.value != "") {
            await getSearchResultImages(data).then(
                response => {
                    commit('SET_SEARCH_RESULT_IMAGES', response.data.payload);
                    commit('SET_IMAGES_GLOBAL_COUNT', response.data.globalCount);
                    commit('SET_IMAGES_LOCAL_COUNT', response.data.localCount);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        } else {
            await getSearchResultImagesNoValue(data).then(
                response => {
                    commit('SET_SEARCH_RESULT_IMAGES', response.data.payload);
                    commit('SET_IMAGES_GLOBAL_COUNT', response.data.globalCount);
                    commit('SET_IMAGES_LOCAL_COUNT', response.data.localCount);
                }, err => { 
                    console.log('inside err ', err)
                }
            )
        }
    }
}

const mutations = {
    SET_SEARCH_RESULT_IMAGES(state, data) {
        state.searchResultImages = data;
    },

    SET_IMAGES_GLOBAL_COUNT(state, data) {
        state.imagesGlobalCount = data;
    },

    SET_IMAGES_LOCAL_COUNT(state, data) {
        state.imagesLocalCount = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}