import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/types";

const getAllTypes = (data) => {
  return service.get(END_POINT + objToQuery(data));
};

const getTypeById = (id) => {
  return service.get(END_POINT + "/by-id/" + id);
};

const searchTypes = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const createType = (data) => {
  return service.post(END_POINT, data);
};

const updateType = (id, data) => {
  return service.put(END_POINT + "/" + id, data);
};

const deleteOneType = (id) => {
  return service.delete(END_POINT + "/hard-delete-one/" + id);
};

const deleteMultipleTypes = (data) => {
  return service.delete(END_POINT + "/hard-delete-multiple", {
    data: JSON.stringify({ ids: data.ids }),
  });
};

const mergeTypes = (data) => {
  return service.post(
    END_POINT + "/merge",
    JSON.stringify({ mainId: data.mainId, otherIds: data.otherIds })
  );
};

export {
  getAllTypes,
  getTypeById,
  searchTypes,
  createType,
  updateType,
  deleteOneType,
  deleteMultipleTypes,
  mergeTypes,
};
