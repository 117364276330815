/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllAuthors,
  searchAuthors,
  createAuthor,
  deleteMultipleAuthors,
  getAuthorById,
  updateAuthor,
  mergeAuthors,
} from "@services/Authors.api";

const state = {
  searchResultAuthors: [],
  searchTermAuthor: "",
  authorsGlobalCount: 0,
  authorsLocalCount: 0,
  numberOfRows: 5,
};

const getters = {
  getSearchResultAuthors(state) {
    return state.searchResultAuthors;
  },
  getSearchTermAuthor(state) {
    return state.searchTermAuthor;
  },
  getAuthorsGlobalCount(state) {
    return state.authorsGlobalCount;
  },
  getAuthorsLocalCount(state) {
    return state.authorsLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllAuthors({ commit }, data) {
    data.loadingFunction(true);
    await getAllAuthors(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_AUTHOR", "");
        commit("SET_SEARCH_RESULT_AUTHORS", response.data.payload);
        commit("SET_AUTHORS_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_AUTHORS_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async addAuthor({ commit }, data) {
    await createAuthor(data).then(
      (response) => {
        commit("ADD_AUTHOR", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },

  async fetchSearchResultAuthors({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_AUTHOR", data.term);
    await searchAuthors(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_AUTHORS", response.data.payload);
        commit("SET_AUTHORS_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_AUTHORS_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getAuthor({}, data) {
    let res = {};
    await getAuthorById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updateAuthor({ commit }, data) {
    const { id, ...toSend } = data;
    await updateAuthor(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_AUTHOR", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deleteAuthor({ commit }, data) {
    data.loadingFunction(true);
    await deleteMultipleAuthors(data).then(
      () => {
        commit("DELETE_AUTHORS", data.ids);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError(
          "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
        );
      }
    );
  },
  async mergeAuthor({ commit }, data) {
    data.loadingFunction(true);
    await mergeAuthors(data).then(
      () => {
        commit("DELETE_AUTHORS", data.otherIds);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError("هناك خطأ ما");
      }
    );
  },
};

const mutations = {
  SET_SEARCH_RESULT_AUTHORS(state, data) {
    state.searchResultAuthors = data;
  },
  SET_SEARCH_TERM_AUTHOR(state, data) {
    state.searchTermAuthor = data;
  },
  ADD_AUTHOR(state, data) {
    state.searchResultAuthors.push(data);
  },
  DELETE_AUTHORS(state, data) {
    const arr = [];
    state.searchResultAuthors.forEach((element) => {
      if (!data.includes(element.id)) {
        arr.push(element);
      }
    });
    state.searchResultAuthors = arr;
  },
  UPDATE_AUTHOR(state, data) {
    let idx = state.searchResultAuthors.findIndex((obj) => obj.id === data.id);
    state.searchResultAuthors[idx] = data;
  },
  SET_AUTHORS_GLOBAL_COUNT(state, data) {
    state.authorsGlobalCount = data;
  },
  SET_AUTHORS_LOCAL_COUNT(state, data) {
    state.authorsLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
