import service from './Service';

const END_POINT = '/entities';

const getSearchResultImages = (data) => { 
    return service.get(END_POINT + '/images/basic-elastic-search-with-counts/'+ data.value + data.query,  { useCache: true })
};

const getSearchResultImagesNoValue = (data) => {
    return service.get(END_POINT + '/images/basic-elastic-search-with-counts-no-val'+ data.query,  { useCache: true })
};

const bookmarkImage = (data) => {
    return service.post(END_POINT + '/users/images', data)
};

const unBookmarkImage = (data) => {
    return service.delete(END_POINT + '/users/'+ data.userId +'/images/' + data.imageId)
};


export {
    getSearchResultImages,
    bookmarkImage,
    unBookmarkImage,
    getSearchResultImagesNoValue
}