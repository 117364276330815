import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/languages";

const getAllLanguages = (data) => {
  return service.get(END_POINT + objToQuery(data));
};

const getLanguageById = (id) => {
  return service.get(END_POINT + "/by-id/" + id);
};

const searchLanguages = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const createLanguage = (data) => {
  return service.post(END_POINT, data);
};

const updateLanguage = (id, data) => {
  return service.put(END_POINT + "/" + id, data);
};

const deleteOneLanguage = (id) => {
  return service.delete(END_POINT + "/hard-delete-one/" + id);
};

const deleteMultipleLanguages = (data) => {
  return service.delete(END_POINT + "/hard-delete-multiple", {
    data: JSON.stringify({ ids: data.ids }),
  });
};

const mergeLanguages = (data) => {
  return service.post(
    END_POINT + "/merge",
    JSON.stringify({ mainId: data.mainId, otherIds: data.otherIds })
  );
};

export {
  getAllLanguages,
  getLanguageById,
  searchLanguages,
  createLanguage,
  updateLanguage,
  deleteOneLanguage,
  deleteMultipleLanguages,
  mergeLanguages,
};
