import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/thesauruses";

const getAllThesauruses = (data) => {
  return service.get(END_POINT + objToQuery(data));
};

const getThesaurusById = (id) => {
  return service.get(END_POINT + "/by-id/" + id);
};

const searchThesauruses = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const createThesaurus = (data) => {
  return service.post(END_POINT, data);
};

const updateThesaurus = (id, data) => {
  return service.put(END_POINT + "/" + id, data);
};

const deleteMultipleThesauruses = (data) => {
  return service.delete(END_POINT + "/hard-delete-multiple", {
    data: JSON.stringify({ ids: data.ids }),
  });
};

const mergeThesauruses = (data) => {
  return service.post(
    END_POINT + "/merge",
    JSON.stringify({ mainId: data.mainId, otherIds: data.otherIds })
  );
};

export {
  getAllThesauruses,
  getThesaurusById,
  searchThesauruses,
  createThesaurus,
  updateThesaurus,
  deleteMultipleThesauruses,
  mergeThesauruses,
};
