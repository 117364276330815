import axios from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";
import config from "@/helpers/config";
// import store from '@/store'

const BASE_URL = config.apiUrl;

const cacheConfig = {
  enabledByDefault: false,
  cacheFlag: "useCache",
};

// const errorInterceptor = error => {
//     // check if it's a server error
//     if (!error.response) {
//     //   notify.warn('Network/Server error');
//       return Promise.reject(error);
//     }

//     // all the error responses
//     switch(error.response.status) {
//         case 400:
//             console.error(error.response.status, error.message);
//             console.log('Nothing to display','Data Not Found');
//             // notify.warn('Nothing to display','Data Not Found');
//             break;

//         case 401:
//             console.log(' authentication error, logout the user');
//             // notify.warn( 'Please login again', 'Session Expired');
//             // localStorage.removeItem('token');
//             // router.push('/auth');
//             break;

//         default:
//             console.error(error.response.status, error.message);
//             // notify.error('Server Error');

//     }
//     return Promise.reject(error);
// }

const token = localStorage.getItem("safir-jwt-token");
const headers = {
  "Content-Type": "application/json",
  Authorization: `Basic ${token}`,
};

// const token = localStorage.getItem('safir-jwt-token')
// if (token) {
//     headers['Authorization'] = `Basic ${token}`
// }

const httpClient = axios.create({
  baseURL: BASE_URL,
  timeout: 90000,
  headers: headers,
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, cacheConfig),
});

const httpClientBackend = axios.create({
  baseURL: BASE_URL,
  timeout: 300000,
  headers,
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, cacheConfig),
});

// const requestInterceptor = config => {
//     store.commit('loaders/SET_IS_PAGE_LOADING', true)
//     return config
// }

// const responseInterceptor = response => {
//     store.commit('loaders/SET_IS_PAGE_LOADING', false)
//     return response
// }

// const errorInterceptor = error => {
//     console.log("ERROR INTERCEPTOR ", error);
// }

// httpClient.interceptors.request.use(requestInterceptor, errorInterceptor)
// httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
export { httpClientBackend };
