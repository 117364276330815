/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllUserRequests,
  // searchUserRequests,
  getUserRequestById,
  createUserRequest,
} from "@services/UserRequests.api";

const state = {
  searchResultUserRequests: [],
  searchTermUserRequest: "",
  userRequestsGlobalCount: 0,
  userRequestsLocalCount: 0,
  numberOfRows: 5,
};

const getters = {
  getSearchResultUserRequests(state) {
    return state.searchResultUserRequests;
  },
  getSearchTermUserRequest(state) {
    return state.searchTermUserRequest;
  },
  getUserRequestsGlobalCount(state) {
    return state.userRequestsGlobalCount;
  },
  getUserRequestsLocalCount(state) {
    return state.userRequestsLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllUserRequests({ commit }, data) {
    data.loadingFunction(true);
    await getAllUserRequests(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_USERQUERY", "");
        commit("SET_SEARCH_RESULT_USERQUERIES", response.data.payload);
        commit("SET_USERQUERIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_USERQUERIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async addUserRequest({ commit }, data) {
    await createUserRequest(data).then(
      (response) => {
        commit("ADD_USERQUERY", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },

  // async fetchSearchResultUserRequests({ commit }, data) {
  //   data.loadingFunction(true);
  //   commit("SET_SEARCH_TERM_USERQUERY", data.term);
  //   await searchUserRequests(data.term, data.request).then(
  //     (response) => {
  //       commit("SET_SEARCH_RESULT_USERQUERIES", response.data.payload);
  //       commit("SET_USERQUERIES_GLOBAL_COUNT", response.data.globalCount);
  //       commit("SET_USERQUERIES_LOCAL_COUNT", response.data.localCount);
  //       data.loadingFunction(false);
  //     },
  //     (err) => {
  //       console.log(err);
  //       data.loadingFunction(false);
  //     }
  //   );
  // },

  async getUserRequest({}, data) {
    let res = {};
    await getUserRequestById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },
};

const mutations = {
  SET_SEARCH_RESULT_USERQUERIES(state, data) {
    state.searchResultUserRequests = data;
  },
  SET_SEARCH_TERM_USERQUERY(state, data) {
    state.searchTermUserRequest = data;
  },
  ADD_USERQUERY(state, data) {
    state.searchResultUserRequests.push(data);
  },
  SET_USERQUERIES_GLOBAL_COUNT(state, data) {
    state.userRequestsGlobalCount = data;
  },
  SET_USERQUERIES_LOCAL_COUNT(state, data) {
    state.userRequestsLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
