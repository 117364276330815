const state = {
  contentEn: {
    locale: 'en'
  },
  contentAr: {
    locale: 'ar'
  }
}

const mutations = {}

const getters = {
  getContentEn: state => state.contentEn,
  getContentAr: state => state.contentAr
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
