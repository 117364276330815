import service from './Service';

const END_POINT = '/entities';

const getArticlesDateStatistics = (data) => { 
    return service.get(END_POINT + '/articles/basic-elastic-search-with-grouping/' + data.value + '/date/2000' + data.query,  { useCache: true })
};

const getArticlesDateStatisticsNoValue = (data) => { 
    return service.get(END_POINT + '/articles/basic-elastic-search-with-grouping-no-val/date/2000' + data.query,  { useCache: true })
};

const getImagesDateStatistics = (data) => { 
    return service.get(END_POINT + '/images/basic-elastic-search-with-grouping/' + data.value + '/date/2000' + data.query,  { useCache: true })
};

const getImagesDateStatisticsNoValue = (data) => { 
    return service.get(END_POINT + '/images/basic-elastic-search-with-grouping-no-val/date/2000' + data.query,  { useCache: true })
};

const getArticlesTopicStatistics = (data) => { 
    return service.get(END_POINT + '/articles/basic-elastic-search-with-grouping/' + data.value + '/topicName.keyword/20000' + data.query,  { useCache: true })
};

const getArticlesTopicStatisticsNoValue = (data) => { 
    return service.get(END_POINT + '/articles/basic-elastic-search-with-grouping-no-val/topicName.keyword/20000' + data.query,  { useCache: true })
};

const getArticlesEntitiesStatistics = (data) => { 
    return service.get(END_POINT + '/articles/basic-elastic-search-with-grouping/' + data.value + '/entities/100' + data.query,  { useCache: true })
};

const getArticlesEntitiesStatisticsNoValue = (data) => { 
    return service.get(END_POINT + '/articles/basic-elastic-search-with-grouping-no-val/entities/100' + data.query,  { useCache: true })
};

const getArticlesAuthorsStatistics = (data) => { 
    return service.get(END_POINT + '/articles/basic-elastic-search-with-grouping/' + data.value + '/authorName.keyword/100' + data.query,  { useCache: true })
};

const getArticlesAuthorsStatisticsNoValue = (data) => { 
    return service.get(END_POINT + '/articles/basic-elastic-search-with-grouping-no-val/authorName.keyword/100' + data.query,  { useCache: true })
};

const getImagesPhotographersStatistics = (data) => { 
    return service.get(END_POINT + '/images/basic-elastic-search-with-grouping/' + data.value + '/photographerName.keyword/100' + data.query,  { useCache: true })
};

const getImagesPhotographersStatisticsNoValue = (data) => { 
    return service.get(END_POINT + '/images/basic-elastic-search-with-grouping-no-val/photographerName.keyword/100' + data.query,  { useCache: true })
};

const getArticlesTypeStatistics = (data) => { 
    return service.get(END_POINT + '/articles/basic-elastic-search-with-grouping/' + data.value + '/typeName.keyword/50' + data.query,  { useCache: true })
};

const getArticlesTypeStatisticsNoValue = (data) => { 
    return service.get(END_POINT + '/articles/basic-elastic-search-with-grouping-no-val/typeName.keyword/50' + data.query,  { useCache: true })
};

const getArticlesSourceStatistics = (data) => { 
    return service.get(END_POINT + '/articles/basic-elastic-search-with-grouping/' + data.value + '/sourceName.keyword/50' + data.query,  { useCache: true })
};

const getArticlesSourceStatisticsNoValue = (data) => { 
    return service.get(END_POINT + '/articles/basic-elastic-search-with-grouping-no-val/sourceName.keyword/50' + data.query,  { useCache: true })
};

const getImagesSourceStatistics = (data) => { 
    return service.get(END_POINT + '/images/basic-elastic-search-with-grouping/' + data.value + '/sourceName.keyword/50' + data.query,  { useCache: true })
};

const getImagesSourceStatisticsNoValue = (data) => { 
    return service.get(END_POINT + '/images/basic-elastic-search-with-grouping-no-val/sourceName.keyword/50' + data.query,  { useCache: true })
};

const getArticlesGroupedBySource = () => { 
    return service.get(END_POINT + '/articles/group-by/sources');
};

const getImagesGroupedBySource = () => { 
    return service.get(END_POINT + '/images/group-by/sources');
};

const getEntitiesAmounts = () => {
    return service.get(END_POINT + '/entities/totals-statistics')
}

export {
    getArticlesDateStatistics,
    getImagesDateStatistics,
    getArticlesTopicStatistics,
    getArticlesEntitiesStatistics,
    getArticlesTypeStatistics,
    getArticlesSourceStatistics,
    getImagesSourceStatistics,
    getArticlesAuthorsStatistics,
    getImagesPhotographersStatistics,
    getArticlesDateStatisticsNoValue,
    getImagesDateStatisticsNoValue,
    getArticlesTopicStatisticsNoValue,
    getArticlesEntitiesStatisticsNoValue,
    getArticlesTypeStatisticsNoValue,
    getArticlesSourceStatisticsNoValue,
    getImagesSourceStatisticsNoValue,
    getArticlesAuthorsStatisticsNoValue,
    getImagesPhotographersStatisticsNoValue,
    getArticlesGroupedBySource,
    getImagesGroupedBySource,
    getEntitiesAmounts
}