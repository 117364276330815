import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/images";

const getAllImages = (data) => {
  return service.get(END_POINT + "/virtuals" + objToQuery(data));
};

const getImageById = (id) => {
  return service.get(END_POINT + "/virtuals/by-id/" + id);
};

const searchImages = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/virtuals/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const simpleSearchImages = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const updateImage = (id, data) => {
  return service.put(END_POINT + "/" + id, data);
};

const deleteImage = (id) => {
  return service.delete(END_POINT + "/hard-delete-one/recursive/" + id);
};

export {
  getAllImages,
  getImageById,
  searchImages,
  updateImage,
  simpleSearchImages,
  deleteImage
};
