/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import {
  getAllEntities,
  getAllEntitiesByType,
  searchEntities,
  createEntity,
  deleteMultipleEntities,
  getEntityById,
  updateEntity,
  mergeEntities,
} from "@services/Entities.api";
import {
  searchEntitiesByLocations,
  searchEntitiesByOrganizations,
  searchEntitiesByPeople,
  searchEntitiesBySubjects,
  searchEntitiesByUnknowns,
} from "../../services/Entities.api";

const state = {
  searchResultEntities: [],
  searchTermEntity: "",
  entitiesGlobalCount: 0,
  entitiesLocalCount: 0,
  numberOfRows: 5,
};

const getters = {
  getSearchResultEntities(state) {
    return state.searchResultEntities;
  },
  getSearchTermEntity(state) {
    return state.searchTermEntity;
  },
  getEntitiesGlobalCount(state) {
    return state.entitiesGlobalCount;
  },
  getEntitiesLocalCount(state) {
    return state.entitiesLocalCount;
  },
  getNumberOfRows(state) {
    return state.numberOfRows;
  },
};

const actions = {
  async fetchAllEntities({ commit }, data) {
    data.loadingFunction(true);
    await getAllEntities(data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_ENTITY", "");
        commit("SET_SEARCH_RESULT_ENTITIES", response.data.payload);
        commit("SET_ENTITIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_ENTITIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async fetchAllEntitiesByType({ commit }, data) {
    data.loadingFunction(true);
    await getAllEntitiesByType(data.entityType, data.query).then(
      (response) => {
        commit("SET_SEARCH_TERM_ENTITY", "");
        commit("SET_SEARCH_RESULT_ENTITIES", response.data.payload);
        commit("SET_ENTITIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_ENTITIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
      }
    );
  },

  async addEntity({ commit }, data) {
    await createEntity(data).then(
      (response) => {
        commit("ADD_ENTITY", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },

  async fetchSearchResultEntities({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_ENTITY", data.term);
    await searchEntities(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_ENTITIES", response.data.payload);
        commit("SET_ENTITIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_ENTITIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async fetchSearchResultEntitiesByPeople({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_ENTITY", data.term);
    await searchEntitiesByPeople(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_ENTITIES", response.data.payload);
        commit("SET_ENTITIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_ENTITIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async fetchSearchResultEntitiesByUnknowns({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_ENTITY", data.term);
    await searchEntitiesByUnknowns(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_ENTITIES", response.data.payload);
        commit("SET_ENTITIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_ENTITIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async fetchSearchResultEntitiesByOrganizations({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_ENTITY", data.term);
    await searchEntitiesByOrganizations(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_ENTITIES", response.data.payload);
        commit("SET_ENTITIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_ENTITIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async fetchSearchResultEntitiesBySubjects({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_ENTITY", data.term);
    await searchEntitiesBySubjects(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_ENTITIES", response.data.payload);
        commit("SET_ENTITIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_ENTITIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async fetchSearchResultEntitiesByLocations({ commit }, data) {
    data.loadingFunction(true);
    commit("SET_SEARCH_TERM_ENTITY", data.term);
    await searchEntitiesByLocations(data.term, data.query).then(
      (response) => {
        commit("SET_SEARCH_RESULT_ENTITIES", response.data.payload);
        commit("SET_ENTITIES_GLOBAL_COUNT", response.data.globalCount);
        commit("SET_ENTITIES_LOCAL_COUNT", response.data.localCount);
        data.loadingFunction(false);
      },
      (err) => {
        console.log(err);
        data.loadingFunction(false);
      }
    );
  },

  async getEntity({}, data) {
    let res = {};
    await getEntityById(data).then(
      (response) => {
        res = response.data.payload;
      },
      (err) => {
        console.log(err);
      }
    );
    return res;
  },

  async updateEntity({ commit }, data) {
    const { id, ...toSend } = data;
    await updateEntity(id, JSON.stringify(toSend)).then(
      (response) => {
        commit("UPDATE_ENTITY", response.data.payload);
      },
      (err) => {
        console.log(err);
      }
    );
  },
  async deleteEntity({ commit }, data) {
    data.loadingFunction(true);
    await deleteMultipleEntities(data).then(
      () => {
        commit("DELETE_ENTITIES", data.ids);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError(
          "!لا يمكن حذف هذا العنصر لانه مستخدم ضمن بيانات أخرى"
        );
      }
    );
  },
  async mergeEntity({ commit }, data) {
    data.loadingFunction(true);
    await mergeEntities(data).then(
      () => {
        commit("DELETE_ENTITIES", data.otherIds);
        data.loadingFunction(false);
      },
      (err) => {
        data.loadingFunction(false);
        data.inCaseOfError("يجب أن تكون الكيانات فريدة!");
      }
    );
  },
};

const mutations = {
  SET_SEARCH_RESULT_ENTITIES(state, data) {
    state.searchResultEntities = data;
  },
  SET_SEARCH_TERM_ENTITY(state, data) {
    state.searchTermEntity = data;
  },
  ADD_ENTITY(state, data) {
    state.searchResultEntities.push(data);
  },
  DELETE_ENTITIES(state, data) {
    const arr = [];
    state.searchResultEntities.forEach((element) => {
      if (!data.includes(element.id)) {
        arr.push(element);
      }
    });
    state.searchResultEntities = arr;
  },
  UPDATE_ENTITY(state, data) {
    let idx = state.searchResultEntities.findIndex((obj) => obj.id === data.id);
    state.searchResultEntities[idx] = data;
  },
  SET_ENTITIES_GLOBAL_COUNT(state, data) {
    state.entitiesGlobalCount = data;
  },
  SET_ENTITIES_LOCAL_COUNT(state, data) {
    state.entitiesLocalCount = data;
  },
  SET_NUMBER_OF_ROWS(state, data) {
    state.numberOfRows = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
