import service from './Service';

const END_POINT = '/entities';

const getSearchResultArticles = (data) => {
	let q = data.query
    if (data.userId !== undefined && q.includes('userId') === false) {
      q = q.includes('?') === false ? q + '?userId=' + data.userId : q + '&userId=' + data.userId
    }
    return service.get(END_POINT + '/articles/basic-elastic-search-with-counts/'+ data.value + q,  { useCache: true })
};

const getSearchResultArticlesNoValue = (data) => {
	let q = data.query
    if (data.userId !== undefined && q.includes('userId') === false) {
      q = q.includes('?') === false ? q + '?userId=' + data.userId : q + '&userId=' + data.userId
    }
    return service.get(END_POINT + '/articles/basic-elastic-search-with-counts-no-val'+ q,  { useCache: true })
};

const bookmarkArticle = (data) => {
    return service.post(END_POINT + '/users/articles', data)
};

const unBookmarkArticle = (data) => {
    return service.delete(END_POINT + '/users/' + data.userId + '/articles/' + data.articleId)
};

export {
    getSearchResultArticles,
    bookmarkArticle,
    unBookmarkArticle,
    getSearchResultArticlesNoValue
}