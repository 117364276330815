import { httpClientBackend as service } from "./Service";
import { objToQuery } from "../mixins/helpers";

const END_POINT = "/entities/user-queries";

const getAllUserQueries = (data) => {
  return service.get(END_POINT + objToQuery(data));
};

const getUserQueryById = (id) => {
  return service.get(END_POINT + "/by-id/" + id);
};

const searchUserQueries = (searchTerm, query) => {
  return service.get(
    END_POINT +
      "/basic-search/" +
      encodeURIComponent(searchTerm) +
      objToQuery(query)
  );
};

const createUserQuery = (data) => {
  return service.post(END_POINT, data);
};

const updateUserQuery = (id, data) => {
  return service.put(END_POINT + "/" + id, data);
};

const deleteMultipleUserQueries = (data) => {
  return service.delete(END_POINT + "/hard-delete-multiple", {
    data: JSON.stringify({ ids: data.ids }),
  });
};

const mergeUserQueries = (data) => {
  return service.post(
    END_POINT + "/merge",
    JSON.stringify({ mainId: data.mainId, otherIds: data.otherIds })
  );
};

export {
  getAllUserQueries,
  getUserQueryById,
  searchUserQueries,
  createUserQuery,
  updateUserQuery,
  deleteMultipleUserQueries,
  mergeUserQueries,
};
