<template>
    <v-container style="margin-top: 100px;">
        <v-row class="text-center">
            <v-col cols="12">
                <v-img src='/images/assafir-landing-page.jpg' class="my-3" contain height="200"/>
            </v-col>
            
            <v-col class="mb-4 col-0 col-md-2"></v-col>
            <v-col class="mb-4 col-12 col-md-8">
                <v-text-field 
                id="yamliTextBox" 
                v-model="searchValue" 
                @keydown.enter="basicSearch" 
                @click:append="basicSearch" 
                :append-icon="'mdi-magnify'" 
                clear-icon="mdi-close-circle" 
                clearable 
                label="كلمات البحث في الأرشيف"
                :loading="loadingArticles || loadingImages"
                :disabled="loadingArticles || loadingImages"
                outlined>
                </v-text-field>
            </v-col>
            <v-col class="mb-4 col-0 col-md-2"></v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import config from '@/helpers/config'

export default {
    data: () => ({
        apiUrl: config.apiUrl,
        loadingArticles: false,
        loadingImages: false,
        searchValue: null,
        articles: [],
        images: [],
        isLoading: false,
        baseQuery: {}
    }),

    mounted () {
        this.$vuetify.rtl = true
        this.searchValue = null
        this.articles = []
        this.images = []
        this.baseQuery = this.getBaseQuery
    },

    computed: {
        ...mapGetters('search', ['getBaseQuery']),
    },

    methods: {
        ...mapMutations('notification', ['notify']),
        ...mapMutations('search', ['SET_SEARCH_VALUE']),

        goToArticle (safirId) {
            let routeData = this.$router.resolve({ path: '/article-view/' + safirId, query: this.$route.query })
            window.open(routeData.href, '_blank');
        },

        goToImage (safirId) {
            let routeData = this.$router.resolve({ path: '/image-view/' + safirId, query: this.$route.query })
            window.open(routeData.href, '_blank');
        },

        isArabic (text) {
            let pattern = /[\u0600-\u06FF\u0750-\u077F]/
            let i = text.length
            while (i--) {
                if (pattern.test(text.charAt(i)) === false && text.charAt(i) !== ' ') {
                return false
                }
            }
            return true
        },
        basicSearch () {
            this.SET_SEARCH_VALUE(this.searchValue)
            var newQuery = JSON.parse(JSON.stringify(this.baseQuery));
            newQuery['value'] = this.searchValue

            // go to summary page
            this.$router.push({
                name: 'summary',
                // params: {
                //     value: this.searchValue
                // },
                query: newQuery
            })

            // To be removed
            this.articles = []
            this.images = []
        },
    },
}
</script>
